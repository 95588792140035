/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import Modal from './Modal.js'
import ImageGraceful from './ImageGraceful.js'

const ImageDialog = ({ src, onClose }) => (
  <Modal size="auto" close={onClose}>
    <div
      onClick={onClose}
      css={{
        cursor: 'zoom-out',
      }}
    >
      <ImageGraceful
        style={{ maxWidth: '90vw', maxHeight: '90vh' }}
        retry={{
          count: 10,
          delay: 1,
          accumulate: false,
        }}
        src={src}
      />
    </div>
  </Modal>
)

export default ImageDialog
