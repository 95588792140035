/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unused-state: 0 */
/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import differenceInSeconds from 'date-fns/differenceInSeconds/index.js'
import formatDistanceToNow from 'date-fns/formatDistanceToNow/index.js'
import format from 'date-fns/format/index.js'
import en from 'date-fns/locale/en-US/index.js'
import zh from 'date-fns/locale/zh-CN/index.js'

import {
  ar,
  bg,
  ca,
  cs,
  da,
  de,
  el,
  es,
  fi,
  fr,
  hr,
  id,
  is,
  it,
  ja,
  ko,
  mk,
  nb,
  nl,
  pl,
  pt,
  ro,
  ru,
  sk,
  sv,
  th,
  tr,
} from 'date-fns/locale/index.js'

const TimeAgo = ({ date, language, className }) => {
  const { t } = useTranslation()
  const [formattedDate, setFormattedDate] = useState('')
  let timeout = null

  const getLocale = () => {
    let languageCode = language !== 'in' ? language : 'id'
    let locales = {
      ar,
      bg,
      ca,
      cs,
      da,
      de,
      el,
      es,
      fi,
      fr,
      hr,
      id,
      is,
      it,
      ja,
      ko,
      mk,
      nb,
      nl,
      pl,
      pt,
      ro,
      ru,
      sk,
      sv,
      th,
      tr,
      zh,
    }
    let locale = locales[languageCode] || en

    return locale
  }

  const getInterval = () => {
    const diff = differenceInSeconds(new Date(), date)

    if (diff < 60) {
      return 1000
    } else if (diff >= 60 && diff < 3600) {
      return 60000
    } else if (diff >= 3600 && diff < 86400) {
      return 3600000
    } else {
      return 86400000
    }
  }

  const updateTime = () => {
    const diffInSeconds = differenceInSeconds(new Date(), date)
    let timeString
    if (diffInSeconds < 60) {
      timeString = t('now')
    } else if (diffInSeconds < 86400) {
      timeString = formatDistanceToNow(date, {
        locale: getLocale(),
        addSuffix: true,
      })
    } else {
      timeString = format(date, 'dd MMM', { locale: getLocale() })
    }

    timeString = timeString.replace(/about/, '')
    timeString = timeString.replace(/ hour ago?/, 'h')
    timeString = timeString.replace(/ hours ago?/, 'h')
    timeString = timeString.replace(/ minute ago?/, 'm')
    timeString = timeString.replace(/ minutes ago?/, 'm')
    timeString = timeString.replace(/ seconds ago?/, 's')
    setFormattedDate(timeString)

    const interval = getInterval()
    if (interval > 0) {
      timeout = setTimeout(updateTime, interval)
    }
  }

  useEffect(() => {
    updateTime()
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [date, timeout])

  return <div css={className}>{formattedDate}</div>
}

export default TimeAgo
