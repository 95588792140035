/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: link;

  color: var(--color-secondary);

  @media (hover: hover) {
    &:not(.disabled):hover {
      color: var(--color-secondary-light);
      cursor: pointer;
    }
  }

  &:not(.disabled):focus-visible {
    color: var(--color-secondary-light);
    cursor: pointer;
  }

  &:not(.disabled):active {
    color: var(--color-secondary-dark);
    cursor: pointer;
  }
`
