/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'
import link from '@stiloso/styles/link.js'
import IllustrationManWorkingOnComputer from '@stiloso/components/IllustrationManWorkingOnComputer.js'

const ForgotPasswordPage = ({ brand, illustration, resetPassword }) => {
  const { t } = useTranslation()
  let [email, setEmail] = useState()
  let [emailedResetLink, setEmailedResetLink] = useState(false)
  const [loading, setLoading] = useState(false)
  let [error, setError] = useState()

  const changeEmail = event => {
    setEmail(event.target.value)
  }

  const showError = error => {
    console.error(error)

    setError(error?.message)
  }

  const onResetPassword = async event => {
    try {
      event.preventDefault()
      setLoading(true)
      await resetPassword({
        email,
      })
      setLoading(false)
      setEmailedResetLink(true)
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  }

  illustration ||= (
    <IllustrationManWorkingOnComputer
      css={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      }}
    />
  )

  return (
    <div
      css={{
        background: 'white',
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <Link to="/">{brand}</Link>
        </div>
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <div
            css={{
              fontSize: 24,
              fontWeight: '700',
              textTransform: 'capitalize',
              lineHeight: '24px',
              color: 'var(--color-base)',
              marginBottom: 32,
            }}
          >
            {t('Forgot password')}
          </div>
          {emailedResetLink && (
            <div>{t('We emailed you a link to reset your password')}</div>
          )}
          {!emailedResetLink && (
            <form onSubmit={onResetPassword}>
              <label htmlFor="newPasswordInput" css={label}>
                {t('email')}
              </label>
              <input
                css={input}
                id="newPasswordInput"
                type="email"
                name="email"
                autoComplete="email"
                autoCapitalize="none"
                autoCorrect="off"
                value={email || ''}
                onChange={changeEmail}
                required
                autoFocus
                data-hj-allow
              />
              <Button
                type="submit"
                css={{ marginTop: 32 }}
                isLoading={loading}
                fullWidth
              >
                {t('Reset password')}
              </Button>
            </form>
          )}
          <div
            css={{
              display: 'flex',
              fontSize: 14,
              lineHeight: '24px',
              color: 'var(--color-base)',
              width: '100%',
              marginTop: 24,
              textAlign: 'center',
            }}
          >
            <Link to="/login" css={link}>
              {t('Login')}
            </Link>
            <div css={{ flexGrow: 1 }} />
            <Link to="/signup" css={link}>
              {t('Sign up')}
            </Link>
          </div>
          {error && (
            <div
              css={{
                textAlign: 'center',
                lineHeight: '24px',
                marginTop: 32,
                color: 'var(--color-red)',
              }}
            >
              {t('error')}
            </div>
          )}
        </div>
        <div css={{ margin: 'auto' }} />
      </div>
      <div css={{ flex: 1, display: 'flex' }}>{illustration}</div>
    </div>
  )
}

export default ForgotPasswordPage
