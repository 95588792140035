/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'
import unselectable from '@stiloso/styles/unselectable.js'
import { useTranslation } from '@multilocale/react/index.js'
import useInvites from '@globo/react/useInvites.js'
import useRoles from '@globo/react/useRoles.js'
import useUsers from '@globo/react/useUsers.js'
import useDeleteInviteMutation from '@globo/react/useDeleteInviteMutation.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import InviteTeamMemberDialog from '@stiloso/components/InviteTeamMemberDialog.js'
import useInviteMutation from '@globo/react/useInviteMutation.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Spinner from '@stiloso/components/Spinner.js'
import section from '@stiloso/styles/section.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import getRoles from '../session/getRoles.js'
import getUserId from '../session/getUserId.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/team']

const ROLES = ['support', 'marketing', 'admin', 'developer']

export const listItem = css`
  ${clickable}
  ${singleLine}
  ${unselectable}
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
`

const avatarContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 48px;
`

const TeamMembers = ({ canInviteTeamMember }) => {
  const { t } = useTranslation()
  const { data: users } = useUsers()
  const { data: roles } = useRoles()

  const members = users.reduce((users, user) => {
    const userRoles = roles.filter(role => role.userId === user._id)

    if (userRoles.length) {
      const roleNames = userRoles.map(userRole => userRole.type)
      users.push({
        userId: user._id,
        email: user.email,
        roles: roleNames.map(roleName => t(roleName)).join(', '),
        initial: user.email.charAt(0),
        color: convertStringToColor(user.email),
        avatarUrl: user.avatarUrl,
      })
    }

    return users
  }, [])

  return (
    <>
      {members.map(member => (
        <Link
          key={member.userId}
          to={`/team/${member.userId}`}
          css={[
            listItem,
            !canInviteTeamMember && {
              pointerEvents: 'none',
            },
          ]}
        >
          {member.avatarUrl && (
            <div css={avatarContainer}>
              <ImageGraceful
                css={{
                  aspectRatio: '1 / 1',
                  height: 48,
                  borderRadius: 1000,
                }}
                src={member.avatarUrl.replace('.jpg', '-256w.webp')}
                fallbackSrc={member.avatarUrl.replace('.jpg', '-256w.jpg')}
              />
            </div>
          )}

          {!member.avatarUrl && (
            <div
              css={[
                avatarContainer,
                {
                  color: 'white',
                  fontSize: 28,
                  background: member.color,
                  textTransform: 'capitalize',
                },
              ]}
            >
              {member.initial}
            </div>
          )}
          <div css={{ margin: 'auto 12px' }}>
            {member.email}
            <div css={{ marginTop: 8, fontWeight: '300' }}>{member.roles}</div>
          </div>
        </Link>
      ))}
    </>
  )
}

const Invitees = ({ canInviteTeamMember }) => {
  const { t } = useTranslation()
  const { mutate, error, isLoading, reset } = useDeleteInviteMutation()
  const { data: invites } = useInvites()
  const [deleteInviteDialog, setDeleteInviteDialog] = useState()

  const invitees = invites.reduce((invitedUsers, invite) => {
    invitedUsers.push({
      _id: invite._id,
      email: invite.email,
      roleName: invite.roleType,
      initial: invite.email.charAt(0),
      color: convertStringToColor(invite.email),
    })

    return invitedUsers
  }, [])

  const showDeleteInviteDialog = invitee => {
    setDeleteInviteDialog({
      _id: invitee._id,
      email: invitee.email,
    })
  }

  const hideDeleteInviteDialog = () => {
    setDeleteInviteDialog(null)
  }

  const deleteInvite = async () => {
    mutate(deleteInviteDialog._id, {
      onSuccess: () => setDeleteInviteDialog(false),
    })
  }

  return (
    <div>
      {invitees?.length > 0 && (
        <>
          {invitees.map(invitee => (
            <div
              key={invitee._id}
              css={[
                listItem,
                !canInviteTeamMember && {
                  pointerEvents: 'none',
                },
              ]}
              onClick={() => showDeleteInviteDialog(invitee)}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 48,
                  width: 48,
                  minWidth: 48,
                  borderRadius: 48,
                  color: 'white',
                  fontSize: 28,
                  background: invitee.color,
                  textTransform: 'capitalize',
                }}
              >
                {invitee.initial}
              </div>
              <div css={{ margin: 'auto 12px' }}>
                {invitee.email}
                <div css={{ marginTop: 8, fontWeight: '300' }}>
                  {t(invitee.roleName)}
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      {deleteInviteDialog && (
        <DeleteDialog
          title={t('Delete invite')}
          close={hideDeleteInviteDialog}
          message={t(
            `Do you want to delete invite ${deleteInviteDialog.email}?`,
          )}
          onDelete={deleteInvite}
          loading={isLoading}
        />
      )}

      {error && <ErrorDialog error={error} close={reset} />}
    </div>
  )
}

const TeamPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  const [inviteTeamMemberDialog, setInviteTeamMemberDialog] = useState(false)

  const canInviteTeamMember = !!getRoles().filter(
    ({ type }) => type === 'admin',
  ).length

  const showInviteTeamMemberDialog = () => {
    setInviteTeamMemberDialog(true)
  }

  const hideInviteTeamMemberDialog = () => {
    setInviteTeamMemberDialog(false)
  }

  return (
    <Layout>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Team')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />

          {canInviteTeamMember && (
            <AppBarButton
              label={t('Invite team member')}
              onClick={showInviteTeamMemberDialog}
            />
          )}
        </AppBar>
        <div css={{ padding: 16 }}>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <TeamMembers canInviteTeamMember={canInviteTeamMember} />
            </Suspense>
          </ErrorBoundary>
          <h3 css={{ margin: '32px 0' }}>{t('Invites')}</h3>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <Invitees canInviteTeamMember={canInviteTeamMember} />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
      {inviteTeamMemberDialog && (
        <InviteTeamMemberDialog
          roles={ROLES}
          userId={getUserId()}
          useInviteMutation={useInviteMutation}
          close={hideInviteTeamMemberDialog}
        />
      )}
    </Layout>
  )
}

export default TeamPage
