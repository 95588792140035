/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import getOs from '@monorepo/shared/device/getOs.js'
import label from '@stiloso/styles/label.js'
import link from '@stiloso/styles/link.js'
import singleLine from '@stiloso/styles/singleLine.js'
import useCurrentCustomer from '../queries/useCurrentCustomer.js'
import useCurrentWebsite from '../queries/useCurrentWebsite.js'
import useLastMessage from '../queries/useLastMessage.js'

const CustomerPanel = () => {
  let website = useCurrentWebsite()
  let customer = useCurrentCustomer()
  let lastMessage = useLastMessage()
  let userAgent = lastMessage?.userAgent
  let version = lastMessage?.version
  let versionNative = lastMessage?.versionNative
  let deviceType = lastMessage?.deviceType
  let os = userAgent ? getOs({ userAgent }) : {}
  let osName = os.name || ''
  osName = osName !== 'unknown' ? osName : ''
  let osVersion = os.version !== 'unknown' ? os.version : ''
  deviceType = deviceType !== 'unknown' ? deviceType : ''

  let iframeUrl = website?.iframeUrl

  Object.keys(customer || {}).forEach(key => {
    if (typeof customer[key] === 'object') {
      Object.keys(customer[key] || {}).forEach(key2 => {
        iframeUrl = iframeUrl?.replace(
          `{customer.metadata.${key2}}`,
          customer.metadata[key2],
        )
      })
    } else {
      iframeUrl = iframeUrl?.replace(`{customer.${key}}`, customer[key])
    }
  })

  return (
    customer?.userId && (
      <div
        css={{
          background: 'white',
          width: 352,
          padding: 16,
          overflow: 'auto',
        }}
      >
        <h4 css={[singleLine, { marginBottom: 4 }]}>
          {customer.nickname || customer.userId}
        </h4>
        {customer.email && (
          <a
            href={`mailto:${customer.email}`}
            target="_blank"
            rel="noopener noreferrer"
            css={{ textDecoration: 'underline' }}
          >
            {customer.email}
          </a>
        )}
        {osName && (
          <div>
            {osName} {osVersion} {deviceType}
          </div>
        )}
        {version && <div>webapp version {version}</div>}
        {versionNative && <div>native version {versionNative}</div>}

        <div css={{ height: 16 }} />

        {iframeUrl && (
          <iframe
            title="waiterio"
            width="100%"
            height="720"
            css={{ borderRadius: 2 }}
            src={iframeUrl}
          />
        )}

        {Object.keys(customer.metadata || {}).map(key => (
          <div key={key}>
            <div css={label}>{key}</div>
            <div>
              {customer.metadata[key].startsWith('http') ? (
                <a
                  href={customer.metadata[key]}
                  css={[
                    link,
                    {
                      wordBreak: 'break-word',
                      fontSize: 16,
                      textDecoration: 'underline',
                    },
                  ]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {customer.metadata[key]}
                </a>
              ) : (
                <span>{customer.metadata[key]}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  )
}

export default CustomerPanel
