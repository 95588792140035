/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */

import React from 'react'

// ;<svg
//   xmlns="http://www.w3.org/2000/svg"
//   height="24px"
//   viewBox="0 0 24 24"
//   width="24px"
//   fill="#000000"
// >
//   <path d="M0 0h24v24H0V0z" fill="none" />
// </svg>

const IconEarth = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-earth'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-.61.08-1.21.21-1.78L8.99 15v1c0 1.1.9 2 2 2v1.93C7.06 19.43 4 16.07 4 12zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41C17.92 5.77 20 8.65 20 12c0 2.08-.81 3.98-2.11 5.4z" />
    </svg>
  )
}

export default IconEarth
