/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getConversations from '@globo/client/getConversations.js'

export default function useConversations(parameters, options) {
  return useQuery({
    queryKey: ['conversations', { ...parameters }],
    queryFn: () => getConversations(parameters),
    ...options,
  })
}
