/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useRef } from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import useWebsites from '@globo/react/useWebsites.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import Button from '@stiloso/components/Button.js'
import inputStyles from '@stiloso/styles/input.js'
import labelStyles from '@stiloso/styles/label.js'
import useSendSetupScript from '@globo/react/useSendSetupScript.js'
import { toast } from '@monorepo/shared/AndroidToast.js'
import IconCopyContent from '@stiloso/icons/IconCopyContent.js'
import IconSend from '@stiloso/icons/IconSend.js'
import getAccessTokenPayload from '../session/getAccessTokenPayload.js'

const PasteScriptSetup = ({ websiteId, dark }) => {
  const copyCodeRef = useRef()
  const [copied, setCopied] = useState(false)
  const [sendViaEmail, setSendViaEmail] = useState(false)
  const [showFinalStep, setShowFinalStep] = useState(false)
  const { t, language } = useTranslation()
  const { data: websites } = useWebsites()
  const [website] = websites
  const {
    mutate: sendSetupScript,
    isSuccess: sendScriptSuccessfully,
    isPending: sendingSetupScript,
    reset: resetSendSetupScript,
  } = useSendSetupScript()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyCodeRef.current.textContent).then(() => {
      setCopied(true)
      toast(t('Copied'))
    })
  }

  const sendEmail = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const recipientEmail = formData.get('recipient')
    const { email: senderEmail } = getAccessTokenPayload()

    sendSetupScript(
      {
        recipientEmail,
        senderEmail,
        language,
      },
      {
        onSuccess: () => {
          event.target.reset()
        },
      },
    )
  }

  return (
    <div
      css={{
        width: '100%',
        borderRadius: 8,
        color: dark ? 'var(--color-base)' : 'white',
      }}
    >
      {!copied && !showFinalStep && (
        <>
          <div
            css={{
              backgroundColor: '#dcdfe4',
              marginBottom: 16,
              fontSize: 16,
              fontWeight: 'bold',
              padding: 8,
              borderRadius: 4,
            }}
          >
            1. {t('Copy the following code')}
          </div>
          <div
            css={{
              backgroundColor: dark ? 'var(--color-base)' : 'white',
              color: dark ? 'white' : 'var(--color-base)',
            }}
          >
            <pre
              css={{
                whiteSpace: 'normal',
                paddingTop: 24,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 24,
                fontSize: 16,
                overflowX: 'auto',
                margin: 0,
              }}
              ref={copyCodeRef}
            >
              &lt;script&gt;
              <div>window.GLOBO_WEBSITE_ID=&quot;{websiteId}&quot;;</div>
              <div css={{ whiteSpace: 'pre-line' }}>
                {'(function () {\n' +
                  'window.$globo = window.$globo || [];\n' +
                  'd = document;\n' +
                  "s= d.createElement('script');\n" +
                  "s.src = 'https://widget.globo.support/widget.js';\n" +
                  's.async = 1;\n' +
                  "d.getElementsByTagName('head')[0].appendChild(s);\n" +
                  '})()'}
              </div>
              &lt;/script&gt;
            </pre>
          </div>
          <div css={[dNone, dLgBlock]}>
            <Button fullWidth css={{ marginTop: 16 }} onClick={copyToClipboard}>
              <IconCopyContent
                css={{
                  fill: 'white',
                  verticalAlign: 'middle',
                  width: 20,
                  height: 20,
                  marginRight: 4,
                }}
              />
              {t('Copy code')}
            </Button>
          </div>

          {sendViaEmail && sendScriptSuccessfully && (
            <Button
              css={{ marginTop: 12 }}
              appearance="secondary"
              fullWidth
              type="button"
              onClick={() => resetSendSetupScript()}
            >
              {t('Email was send successfully')}
            </Button>
          )}

          {sendViaEmail && !sendScriptSuccessfully && (
            <form css={{ margin: 8 }} onSubmit={sendEmail}>
              <label css={labelStyles} htmlFor="recipient">
                {t('To')}
              </label>
              <input
                name="recipient"
                id="recipient"
                css={inputStyles}
                autoFocus
                required
                type="email"
              />
              <Button
                css={{ marginTop: 12 }}
                appearance="secondary"
                fullWidth
                type="submit"
                isLoading={sendingSetupScript}
              >
                <IconSend
                  css={{
                    verticalAlign: 'middle',
                    width: 18,
                    height: 18,
                    marginRight: 4,
                  }}
                />
                {t('Send code')}
              </Button>
            </form>
          )}
          {!sendViaEmail && (
            <Button
              css={{ marginTop: 12 }}
              appearance="secondary"
              onClick={() => setSendViaEmail(true)}
              fullWidth
            >
              <IconSend
                css={{
                  verticalAlign: 'middle',
                  width: 18,
                  height: 18,
                  marginRight: 4,
                }}
              />
              {t('Email code')}
            </Button>
          )}
        </>
      )}
      {copied && !showFinalStep && (
        <div>
          <div
            css={{
              backgroundColor: '#dcdfe4',
              marginBottom: 16,
              fontSize: 16,
              fontWeight: 'bold',
              padding: 8,
              borderRadius: 4,
            }}
          >
            2.{' '}
            {t(
              'Open your html file in a text editor and Paste the script at the end of <body> section of your HTML file.',
            )}
          </div>
          <video width="540" height="300" loop autoPlay controls>
            <source
              src="https://waiterio-images-staging.s3.amazonaws.com/images/setup-guide-720p.mov"
              type="video/mp4"
            />
          </video>
          <div css={[dNone, dLgBlock]}>
            <Button
              fullWidth
              css={{ marginTop: 16 }}
              onClick={() => setShowFinalStep(true)}
            >
              {t('Done')}
            </Button>
          </div>
        </div>
      )}
      {showFinalStep && (
        <div>
          <div
            css={{
              backgroundColor: '#dcdfe4',
              marginBottom: 16,
              fontSize: 16,
              fontWeight: 'bold',
              padding: 8,
              borderRadius: 4,
            }}
          >
            3. {t('Open your website and see the chat widget')}
          </div>
          <video width="540" height="300" loop autoPlay controls>
            <source
              src="https://waiterio-images-staging.s3.amazonaws.com/images/setup-guide-2-720p.mov"
              type="video/mp4"
            />
          </video>
          <div css={[dNone, dLgBlock]}>
            <Button
              fullWidth
              css={{ marginTop: 16 }}
              to={`/websites/${website.name}/conversations`}
            >
              {t('Finish setup')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default PasteScriptSetup
