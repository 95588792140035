/* Copyright 2013 - 2024 Waiterio LLC */
import getScreenWidth from './getScreenWidth.js'
import getUserAgent from './getUserAgent.js'
import isAndroid from './isAndroid.js'
import isiOS from './isiOS.js'
import isLinux from './isLinux.js'
import isMacOS from './isMacOS.js'
import isWindows from './isWindows.js'

const getOsVersion = params => {
  let osVersion = 'unknown'
  try {
    params ||= {}
    let { userAgent, screenWidth } = params
    screenWidth ||= getScreenWidth()
    userAgent ||= getUserAgent()

    if (isAndroid(userAgent)) {
      let splits = userAgent.split('Android')
      let after = splits[1]
      osVersion = after.split(';')[0].trim()
    } else if (isiOS(userAgent)) {
      let splits = userAgent.split(' like Mac OS X')
      let before = splits[0]
      osVersion = before.split('iPhone OS ')[1].trim()
      osVersion = osVersion.replace(/_/g, '.')
      while (osVersion.match(/\./g).length < 2) {
        osVersion += '.0'
      }
    } else if (isLinux(userAgent)) {
      // osVersion = 'Linux'
    } else if (isMacOS(userAgent)) {
      let splits = userAgent.split('Intel Mac OS X')
      let before = splits[1]
      osVersion = before.split(')')[0].trim()
      osVersion = osVersion.replace(/_/g, '.')
      while (osVersion.match(/\./g).length < 2) {
        osVersion += '.0'
      }

      if (osVersion.startsWith('10.15')) {
        osVersion = 'unknown'
      }
    } else if (isWindows(userAgent)) {
      let splits = userAgent.split('Windows')
      let after = splits[1]
      after = after.replace('NT', '')
      osVersion = after.split(';')[0].trim()
    }
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }

  return osVersion
}

export default getOsVersion
