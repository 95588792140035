/* Copyright 2013 - 2024 Waiterio LLC */
import Pusher from 'pusher-js'
import getApiUrl from '@globo/shared/getApiUrl.js'

let pusher

export default function getPusher({ paramsProvider } = {}) {
  if (!pusher) {
    pusher = new Pusher('ed727a36d3360377c9a4', {
      cluster: 'mt1',
      forceTLS: process.env.NODE_ENV === 'production',
      channelAuthorization: {
        endpoint: `${getApiUrl()}/api/pusher/auth`,
        paramsProvider,
      },
    })
  }

  return pusher
}
