/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import useWebsites from '@globo/react/useWebsites.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/websites']

let websiteCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
  },
])

const WebsitesList = () => {
  let { data: websites } = useWebsites()
  websites = websites?.sort((a, b) => (a.name > b.name ? 1 : -1))
  return (
    <div
      css={{
        padding: 16,
        display: 'flex',
        direction: 'column',
        flexWrap: 'wrap',
      }}
    >
      {websites?.map(({ name }) => (
        <Link
          key={name}
          css={websiteCard}
          to={`/websites/${name}/conversations/`}
        >
          {name}
        </Link>
      ))}
    </div>
  )
}

const WebsitesPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout>
      <div css={{ margin: '32px auto', maxWidth: 640, width: '100%' }}>
        <AppBar>
          <AppBarTitle>{t('Websites')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton label={t('New website')} to="/websites/new" />
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <WebsitesList />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default WebsitesPage
