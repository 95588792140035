/* Copyright 2013 - 2024 Waiterio LLC */
import {
  setAccessTokenForGloboClient,
  setAccessTokenCallbackForGloboClient,
} from '@globo/client/accessToken.js'
import { setRefreshTokenForGloboClient } from '@globo/client/refreshToken.js'
import getAccessToken from './getAccessToken.js'
import getRefreshToken from './getRefreshToken.js'
import setAccessToken from './setAccessToken.js'

export default async function rehydrateSession() {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  setAccessTokenForGloboClient(accessToken)
  setRefreshTokenForGloboClient(refreshToken)
  setAccessTokenCallbackForGloboClient(setAccessToken)
}
