/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, Suspense } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import { jsx } from '@emotion/react'
import useRoles from '@globo/react/useRoles.js'
import useUser from '@globo/react/useUser.js'
import useAddRoleMutation from '@globo/react/useAddRoleMutation.js'
import useDeleteRoleMutation from '@globo/react/useDeleteRoleMutation.js'
import list from '@stiloso/styles/list.js'
import listItem from '@stiloso/styles/listItem.js'
import IconDone from '@stiloso/icons/IconDone.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import Spinner from '@stiloso/components/Spinner.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'

import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/team/:userId']

const ROLES = ['support', 'marketing', 'admin', 'developer']

const User = () => {
  const { t } = useTranslation()
  const { userId } = useParams()
  const { data: user } = useUser(userId)

  return (
    <div css={{ padding: 16, backgroundColor: 'white', borderRadius: 4 }}>
      <div
        css={{
          color: '#273854',
          fontWeight: 'bold',
          fontSize: 14,
          marginBottom: 8,
          textTransform: 'capitalize',
        }}
      >
        {t('email')}
      </div>
      <div css={{ color: '#6A768A' }}>{user.email}</div>
    </div>
  )
}

const UserRoles = () => {
  const { t } = useTranslation()
  const { data: roles } = useRoles()
  const { userId } = useParams()
  const navigate = useNavigate()

  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)

  const userRoles = roles
    .filter(role => role.userId === userId)
    .map(role => role.type)

  const addRoleMutation = useAddRoleMutation()
  const deleteRoleMutation = useDeleteRoleMutation()

  const showDeleteConfirmationDialog = userRole => {
    setDeleteConfirmationDialog({ userRole })
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  const addRole = roleType => {
    addRoleMutation.mutate({
      userId,
      type: roleType,
    })
  }

  const deleteRole = roleType => {
    const userRole = roles.find(
      role => role.userId === userId && role.type === roleType,
    )
    if (userRoles.length === 1) {
      showDeleteConfirmationDialog(userRole)
    } else {
      deleteRoleMutation.mutate(userRole._id)
    }
  }

  const removeTeamMember = () => {
    const { userRole } = deleteConfirmationDialog
    deleteRoleMutation.mutate(userRole._id, {
      onSuccess: () => {
        hideDeleteConfirmationDialog()
        navigate('/team')
      },
    })
  }

  return (
    <>
      <div css={list}>
        {ROLES.map(role => (
          <div key={role} css={[listItem]}>
            {userRoles.includes(role) ? (
              <div
                css={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => deleteRole(role)}
              >
                <div>{role}</div>
                <IconDone />
              </div>
            ) : (
              <div onClick={() => addRole(role)}>{role}</div>
            )}
          </div>
        ))}
      </div>

      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Remove team member')}
          message={t('Do you want to remove this team member?')}
          onDelete={removeTeamMember}
          close={hideDeleteConfirmationDialog}
          loading={deleteRoleMutation.isLoading}
        />
      )}

      {addRoleMutation.error && (
        <ErrorDialog
          error={addRoleMutation.error}
          close={addRoleMutation.reset}
        />
      )}
      {deleteRoleMutation.error && (
        <ErrorDialog
          error={deleteRoleMutation.error}
          close={deleteRoleMutation.reset}
        />
      )}
    </>
  )
}
const TeamMemberPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout>
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 512,
          margin: '32px auto',
          padding: 16,
        }}
      >
        <div
          css={{
            fontWeight: 'bold',
            marginBottom: 16,
            color: '#273854',
            textTransform: 'capitalize',
          }}
        >
          {t('details')}
        </div>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <User />
          </Suspense>
        </ErrorBoundary>
      </div>

      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 512,
          margin: '32px auto',
          padding: 16,
        }}
      >
        <div
          css={{
            fontWeight: 'bold',
            color: '#273854',
          }}
        >
          {t('Roles')}
        </div>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <UserRoles />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default TeamMemberPage
