/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function translateConversation(parameters) {
  let url = 'translate-conversation'

  url += '?' + new URLSearchParams(parameters).toString()

  return get({
    url,
  }).catch(error => {
    throw new Error(`Couldn't translate conversation: ${error}`)
  })
}
