/* Copyright 2013 - 2024 Waiterio LLC */
import fetch from '@monorepo/fetch/index.js'
import getApiUrl from '@globo/shared/getApiUrl.js'

const postForgotPassword = email =>
  fetch({
    url: getApiUrl() + '/api/forgot-password',
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(email)}`,
    },
  })

export default postForgotPassword
