/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import Layout from '../components/Layout.js'
import ConversationsMessagesPanel from './ConversationsMessagesPanel.js'

export const paths = ['/websites/:websiteName/conversations/:conversationId']

const ConversationPage = () => (
  <Layout>
    <ConversationsMessagesPanel />
  </Layout>
)

export default ConversationPage
