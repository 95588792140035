/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import Modal from '@stiloso/components/Modal.js'
import { useTranslation } from '@multilocale/react/index.js'
import link from '@stiloso/styles/link.js'

const ShopifyIntegrationDialog = ({ close }) => {
  const { t } = useTranslation()

  return (
    <Modal close={close} size="medium">
      <div
        css={{
          padding: 24,
        }}
      >
        <div
          css={{
            backgroundColor: '#dcdfe4',
            marginBottom: 16,
            fontSize: 16,
            fontWeight: 'bold',
            padding: 8,
            borderRadius: 4,
          }}
        >
          1. {t('Install the plugin')}
        </div>
        <p css={{ fontSize: 16 }}>
          {t('Install')}&nbsp;
          <a
            rel="noreferrer"
            href="https://apps.shopify.com/globo-support"
            target="_blank"
            css={link}
          >
            Globo shopify
          </a>
          &nbsp;{t('plugin')}
        </p>
        <div
          css={{
            backgroundColor: '#dcdfe4',
            marginBottom: 16,
            fontSize: 16,
            fontWeight: 'bold',
            padding: 8,
            borderRadius: 4,
            marginTop: 40,
          }}
        >
          2. {t('Add your Website ID')}
        </div>
        <p css={{ fontSize: 16 }}>
          {t(
            'Add your Globo Website ID in the input field shown in the app page and click "Save"',
          )}
        </p>

        <div
          css={{
            backgroundColor: '#dcdfe4',
            marginBottom: 16,
            fontSize: 16,
            fontWeight: 'bold',
            padding: 8,
            borderRadius: 4,
            marginTop: 40,
          }}
        >
          3. {t('Enable Globo App block in your Shopify theme')}
        </div>
        <p css={{ fontSize: 16 }}>
          {t(
            'After saving Globo Website ID. Click on "Enable Globo Chat" and the widget will be added to your shopify theme',
          )}
        </p>
      </div>
    </Modal>
  )
}

export default ShopifyIntegrationDialog
