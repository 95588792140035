/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'

const AppBarTitle = ({ children }) => (
  <div
    css={{
      color: 'var(--color-base)',
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: '72px',
    }}
  >
    {children}
  </div>
)

export default AppBarTitle
