/* Copyright 2013 - 2024 Waiterio LLC */
import setCookie from '@monorepo/shared/setCookie.js'
import {
  setAccessTokenForGloboClient,
  setAccessTokenCallbackForGloboClient,
} from '@globo/client/accessToken.js'
import { setRefreshTokenForGloboClient } from '@globo/client/refreshToken.js'
import getAccessTokenPayload from './getAccessTokenPayload.js'
import setAccessToken from './setAccessToken.js'
import setRefreshToken from './setRefreshToken.js'
import identify from '../identify.js'

export default async function storeNewSession(loginResponse) {
  const { accessToken, refreshToken } = loginResponse
  try {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)

    setAccessTokenForGloboClient(accessToken, setAccessToken)
    setRefreshTokenForGloboClient(refreshToken, setRefreshToken)
    setAccessTokenCallbackForGloboClient(setAccessToken)

    let accessTokenPayload = getAccessTokenPayload()

    setCookie('globoUserId', accessTokenPayload?.metadata?.userId)
    setCookie('globoSignature', accessTokenPayload?.metadata?.globoSignature)

    identify()

    return true
  } catch (error) {
    console.error('error', error)
    throw error
  }
}
