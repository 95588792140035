/* Copyright 2013 - 2024 Waiterio LLC */
import post from './post.js'

export default function addInvite(body) {
  return post({ url: 'invites', body }).catch(error => {
    if (error.status === 409) {
      throw new Error(error)
    } else {
      throw new Error(`Couldn't add invite: ${error}`)
    }
  })
}
