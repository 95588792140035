/* Copyright 2013 - 2024 Waiterio LLC */
import useCustomer from '@globo/react/useCustomer.js'
import useCurrentConversation from './useCurrentConversation.js'

const useCurrentCustomer = () => {
  let currentConversation = useCurrentConversation()
  let { data: currentCustomer } = useCustomer(currentConversation?.customerId, {
    enabled: !!currentConversation?.customerId,
  })
  return currentCustomer
}

export default useCurrentCustomer
