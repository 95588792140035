/* Copyright 2013 - 2024 Waiterio LLC */
import getAccessTokenPayload from './getAccessTokenPayload.js'

export default function getRoles() {
  let roles
  let payload = getAccessTokenPayload()

  if (payload) {
    roles = payload?.metadata?.roles
  }

  return roles
}
