/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'
import link from '@stiloso/styles/link.js'
import IllustrationManWorkingOnComputer from '@stiloso/components/IllustrationManWorkingOnComputer.js'

const ChangePasswordPage = ({ brand, illustration, changePassword }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let [newPassword, setNewPassword] = useState()
  let [confirmPassword, setConfirmPassword] = useState()
  const [loading, setLoading] = useState(false)
  let [error, setError] = useState()
  const [searchParams] = useSearchParams()
  const forgotPasswordToken = searchParams.get('token')

  useEffect(() => {
    if (!forgotPasswordToken) {
      navigate('/forgot-password')
    }
  }, [forgotPasswordToken, navigate])

  const changeNewPassword = event => {
    setNewPassword(event.target.value)
  }

  const changeConfirmPassword = event => {
    setConfirmPassword(event.target.value)
  }

  const showError = error => {
    console.error(error)

    setError(error?.message)
  }

  const onChangePassword = async event => {
    try {
      event.preventDefault()
      if (newPassword !== confirmPassword) {
        showError(new Error('Passwords do not match'))
      } else {
        setLoading(true)
        await changePassword({
          newPassword: btoa(newPassword),
          forgotPasswordToken: btoa(forgotPasswordToken),
        })
        setLoading(false)
        navigate('/login?passwordChanged=true')
      }
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  }

  illustration ||= (
    <IllustrationManWorkingOnComputer
      css={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      }}
    />
  )

  return (
    <div
      css={{
        background: 'white',
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <Link to="/">{brand}</Link>
        </div>
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <div
            css={{
              fontSize: 24,
              fontWeight: '700',
              textTransform: 'capitalize',
              lineHeight: '24px',
              color: 'var(--color-base)',
              marginBottom: 32,
            }}
          >
            {t('Change password')}
          </div>
          {forgotPasswordToken && (
            <form onSubmit={onChangePassword} data-form-type="change_password">
              <label htmlFor="newPasswordInput" css={label}>
                {t('new password')}
              </label>
              <input
                css={input}
                id="newPasswordInput"
                type="password"
                name="password"
                autoComplete="new-password"
                autoCapitalize="none"
                autoCorrect="off"
                minLength="6"
                value={newPassword || ''}
                onChange={changeNewPassword}
                data-form-type="password,new"
                required
                autoFocus
              />

              <label htmlFor="confirmPasswordInput" css={label}>
                {t('confirm password')}
              </label>
              <input
                css={[
                  input,
                  {
                    backgroundColor:
                      !confirmPassword || confirmPassword === newPassword
                        ? 'inherit'
                        : 'rgba(255, 0, 0, 0.2)',
                  },
                ]}
                id="confirmPasswordInput"
                type="password"
                name="password"
                autoComplete="confirm-password"
                autoCapitalize="none"
                autoCorrect="off"
                minLength="6"
                value={confirmPassword || ''}
                onChange={changeConfirmPassword}
                data-form-type="password,confirmation"
                required
              />

              <Button
                type="submit"
                css={{ marginTop: 32 }}
                isLoading={loading}
                fullWidth
              >
                {t('Save')}
              </Button>
            </form>
          )}
          <div
            css={{
              display: 'flex',
              fontSize: 14,
              lineHeight: '24px',
              color: 'var(--color-base)',
              width: '100%',
              marginTop: 24,
              textAlign: 'center',
            }}
          >
            <Link to="/login" css={link}>
              {t('Login')}
            </Link>
            <div css={{ flexGrow: 1 }} />
            <Link to="/signup" css={link}>
              {t('Sign up')}
            </Link>
          </div>
          {error && (
            <div
              css={{
                textAlign: 'center',
                lineHeight: '24px',
                marginTop: 32,
                color: 'var(--color-red)',
              }}
            >
              {t('error')}
            </div>
          )}
        </div>
        <div css={{ margin: 'auto' }} />
      </div>
      <div css={{ flex: 1, display: 'flex' }}>{illustration}</div>
    </div>
  )
}

export default ChangePasswordPage
