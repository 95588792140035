/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'

export const tab = css([
  clickable,
  {
    display: 'flex',
    padding: '0px 16px',
    border: 9,
    margin: 0,
    width: '100%',
    background: 'transparent',
    fontSize: 14,
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
])

export const selectedTab = css`
  &,
  &:active {
    box-shadow: 0px -4px 0px var(--color-primary) inset;
    color: var(--color-primary);
  }
`

export const tabText = css([singleLine, {}])

const Tab = ({ children, onClick, selected, styles, dataTestId }) => (
  <div
    type="button"
    css={[tab, selected && selectedTab, styles]}
    onClick={onClick}
    data-testid={dataTestId}
  >
    <span css={tabText}>{children}</span>
  </div>
)

export default Tab
