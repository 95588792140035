/* Copyright 2013 - 2024 Waiterio LLC */

export default function fuzzySearch({ query, list, properties, options = {} }) {
  const { threshold = 0.05 } = options

  function calculateScore(item, property) {
    const queryLoweredCase = query.toLowerCase()
    const itemLoweredCase = item[property]?.toLowerCase()
    let score = 0
    let i = 0
    let j = 0

    if (itemLoweredCase) {
      while (i < queryLoweredCase.length && j < itemLoweredCase.length) {
        if (queryLoweredCase[i] === itemLoweredCase[j]) {
          score += 1 / (j - i + 1)
          i += 1
        }
        j += 1
      }
    }

    return score / queryLoweredCase.length
  }

  const scoredResults = list.map(item => {
    // Calculate scores for each property and use the max score
    const maxScore = Math.max(
      ...properties.map(prop => calculateScore(item, prop)),
    )
    return {
      item,
      score: maxScore,
    }
  })

  const filteredResults = scoredResults
    .filter(({ score }) => score >= threshold)
    .sort((a, b) => b.score - a.score)

  return filteredResults.map(({ item }) => item)
}
