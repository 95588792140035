/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import Modal from '@stiloso/components/Modal.js'
import { useTranslation } from '@multilocale/react/index.js'

const WordpressIntegrationDialog = ({ close }) => {
  const { t } = useTranslation()

  return (
    <Modal close={close} size="medium">
      <div
        css={{
          padding: 24,
        }}
      >
        <div
          css={{
            backgroundColor: '#dcdfe4',
            marginBottom: 16,
            fontSize: 16,
            fontWeight: 'bold',
            padding: 8,
            borderRadius: 4,
          }}
        >
          1. {t('Get the plugin')}
        </div>
        <p css={{ fontSize: 16 }}>
          {t(
            'Go to your wordpress dashboard. Click on Plugins, Add new and then search for "Globo". Install and activate it.',
          )}
        </p>
        <div
          css={{
            backgroundColor: '#dcdfe4',
            marginBottom: 16,
            fontSize: 16,
            fontWeight: 'bold',
            padding: 8,
            borderRadius: 4,
            marginTop: 40,
          }}
        >
          2. {t('Connect with Globo app')}
        </div>
        <p css={{ fontSize: 16 }}>
          {t(
            'Click on Globo Settings from the left menu. Follow the instructions on the page to setup website and click "Save Website"',
          )}
        </p>
        <div
          css={{
            backgroundColor: '#dcdfe4',
            marginBottom: 16,
            fontSize: 16,
            fontWeight: 'bold',
            padding: 8,
            borderRadius: 4,
            marginTop: 40,
          }}
        >
          3. {t('Test Globo')}
        </div>
        <p css={{ fontSize: 16 }}>
          {t(
            'Go to your wordpress website and you should now see the Globo widget on the bottom right. You have successfully connected globo chat to your wordpress website.',
          )}
        </p>
      </div>
    </Modal>
  )
}

export default WordpressIntegrationDialog
