/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getWebsite from '@globo/client/getWebsite.js'

export default function useWebsite(websiteIdOrName, organizationId) {
  return useQuery({
    queryKey: ['websites', websiteIdOrName],
    queryFn: () => getWebsite(websiteIdOrName, organizationId),
    enabled: !!websiteIdOrName,
  })
}
