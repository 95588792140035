/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconHelp = props => {
  const { className, width = 14, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-help'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 14 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M9.432 16.824v3.216q0 0.216-0.168 0.36t-0.36 0.168h-3.216q-0.216 0-0.384-0.168t-0.168-0.36v-3.216q0-0.216 0.168-0.384t0.384-0.144h3.216q0.216 0 0.36 0.144t0.168 0.384zM13.656 8.784q0 0.72-0.192 1.344t-0.48 1.032-0.744 0.792-0.768 0.6-0.816 0.456q-0.552 0.312-0.912 0.888t-0.36 0.888q0 0.24-0.168 0.432t-0.384 0.216h-3.216q-0.192 0-0.336-0.264t-0.144-0.48v-0.624q0-1.104 0.888-2.088t1.896-1.44q0.792-0.384 1.128-0.768t0.336-1.008q0-0.576-0.624-0.984t-1.44-0.432q-0.864 0-1.44 0.384-0.48 0.336-1.44 1.536-0.168 0.216-0.408 0.216-0.168 0-0.336-0.096l-2.184-1.68q-0.192-0.144-0.216-0.336t0.072-0.384q2.136-3.552 6.216-3.552 1.080 0 2.16 0.408t1.944 1.104 1.416 1.728 0.552 2.112z" />
    </svg>
  )
}

export default IconHelp
