/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import getPusher from './getPusher.js'

export default function usePresenceSubscription({
  customerId,
  operatorId,
  websiteId,
}) {
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (websiteId && (customerId || operatorId)) {
      let channelName = `presence-website_${websiteId}`

      let pusher = getPusher({
        paramsProvider: () =>
          JSON.parse(JSON.stringify({ customerId, operatorId, websiteId })),
      })
      // console.log({ pusher })
      const channel = pusher.subscribe(channelName)

      channel.bind('pusher:subscription_succeeded', event => {
        // console.log('pusher:subscription_succeeded', event.members)
        let usersIds = Object.keys(channel.members.members)
        queryClient.setQueryData(['presence'], usersIds)
      })

      channel.bind('pusher:member_added', event => {
        // console.log('pusher:member_added', event)
        let usersIds = Object.keys(channel.members.members)
        queryClient.setQueryData(['presence'], usersIds)
      })

      channel.bind('pusher:member_removed', event => {
        // console.log('pusher:member_removed', event)
        let usersIds = Object.keys(channel.members.members)
        queryClient.setQueryData(['presence'], usersIds)
      })

      return () => {
        channel.unbind('pusher:subscription_succeeded')
        channel.unbind('pusher:member_added')
        channel.unbind('pusher:member_removed')
        getPusher().unsubscribe(channelName)
        console.log(`pusher unsubscribe channel ${channelName}`)
      }
    }
  }, [customerId, websiteId])
}
