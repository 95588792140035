/* Copyright 2013 - 2024 Waiterio LLC */
const languages2DefaultCountries = {
  af: 'ZA',
  am: 'ET',
  ar: 'SA',
  az: 'AZ',
  be: 'BY',
  bg: 'BG',
  bi: 'VU',
  bn: 'BD',
  bs: 'BA',
  ca: 'AD',
  cs: 'CZ',
  da: 'DK',
  de: 'DE',
  dv: 'MV',
  dz: 'BT',
  el: 'GR',
  en: 'US',
  es: 'ES',
  et: 'EE',
  fa: 'IR',
  fi: 'FI',
  fo: 'FO',
  fr: 'FR',
  ga: 'IE',
  he: 'IL',
  hi: 'IN',
  hr: 'HR',
  hu: 'HU',
  hy: 'AM',
  id: 'ID',
  is: 'IS',
  it: 'IT',
  ja: 'JP',
  ka: 'GE',
  kk: 'KZ',
  kl: 'GL',
  km: 'KH',
  ko: 'KR',
  ky: 'KG',
  lo: 'LA',
  lt: 'LT',
  lv: 'LV',
  mk: 'MK',
  mn: 'MN',
  ms: 'MY',
  mt: 'MT',
  my: 'MM',
  ne: 'NP',
  nl: 'NL',
  no: 'NO',
  pl: 'PL',
  ps: 'AF',
  pt: 'PT',
  ro: 'RO',
  ru: 'RU',
  rw: 'RW',
  si: 'LK',
  sk: 'SK',
  sl: 'SI',
  sm: 'WS',
  so: 'SO',
  sq: 'AL',
  sr: 'RS',
  sv: 'SE',
  sw: 'TZ',
  tg: 'TJ',
  th: 'TH',
  ti: 'ER',
  tk: 'TM',
  tr: 'TR',
  uk: 'UA',
  uz: 'UZ',
  vi: 'VN',
  zh: 'CN',
}

export default languages2DefaultCountries
