/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { useTranslation } from '@multilocale/react/index.js'
import { jsx, css } from '@emotion/react'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'
import languages from '@stiloso/shared/languages.js'
import { modalOuter, backdrop } from './Modal.js'
import Button from './Button.js'
import LanguageAutocomplete from './LanguageAutocomplete.js'

const modal = css`
  box-sizing: border-box;
  width: 500px;
  height: auto;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9998;
  background: #ffffff;
`

const AddLanguageDialog = ({ item, useUpdateMutation, close }) => {
  const { t } = useTranslation()
  const { locales } = item
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const { mutate, isLoading, isError, reset, error } = useUpdateMutation()

  const languages_ = Object.keys(languages)
    .map(languageCode => ({
      code: languageCode,
      name: t(getLanguageNameByCode(languageCode)),
    }))
    .filter(language => !locales.includes(language.code))

  const onSubmit = async event => {
    event.preventDefault()

    if (isError) {
      reset()
    }

    const updatedLocales = [...locales, selectedLanguage]
    const updatedItem = {
      ...item,
      locales: updatedLocales,
    }

    mutate(updatedItem, {
      onSuccess: () => close(selectedLanguage),
    })
  }

  return (
    <div css={modalOuter}>
      <div css={modal}>
        <div
          css={{ fontWeight: 'bold', margin: 32, color: 'var(--color-base)' }}
        >
          {t('Add language')}
        </div>
        <form onSubmit={onSubmit} css={{ margin: 32 }}>
          <LanguageAutocomplete
            languages={languages_}
            selectedLanguage={selectedLanguage}
            onSelectLanguage={setSelectedLanguage}
            autoFocused
          />
          <div
            css={{
              marginTop: 32,
              marginBottom: 32,
              height: 2,
              backgroundColor: '#D8E0ED',
            }}
          />
          <Button type="submit" isLoading={isLoading} fullWidth>
            {t('Add language')}
          </Button>
        </form>

        {error && (
          <div
            css={{
              margin: 32,
              color: 'red',
            }}
          >
            {t(error?.message)}
          </div>
        )}
      </div>
      <div css={backdrop} onClick={close} data-testid="close-modal" />
    </div>
  )
}

export default AddLanguageDialog
