/* Copyright 2013 - 2024 Waiterio LLC */
import post from './post.js'

export default function translateDocument(documentId, locale) {
  return post({
    url: 'translate-document',
    body: {
      documentId,
      locale,
    },
  }).catch(error => {
    throw new Error(`Couldn't translate document: ${error}`)
  })
}
