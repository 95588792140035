/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { Suspense } from 'react'
import { jsx } from '@emotion/react'
import Spinner from '@stiloso/components/Spinner.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import ConversationsPanel from './ConversationsPanel.js'

const ConversationsSmartphonePage = () => {
  useLoggedInSession()

  return (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ConversationsPanel />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default ConversationsSmartphonePage
