/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import ChangePasswordPage from '@stiloso/pages/ChangePasswordPage.js'
import postChangePassword from '../postChangePassword.js'

export const paths = ['/changepassword', '/change-password']

const ChangePasswordPageWrapper = () => {
  const { t } = useTranslation()
  const changePassword = async ({ newPassword, forgotPasswordToken }) => {
    await postChangePassword({ newPassword, forgotPasswordToken })
  }

  let brand = (
    <div css={{ color: 'var(--color-primary)', fontSize: 36 }}>
      {t('__brand__')}
    </div>
  )

  return <ChangePasswordPage brand={brand} changePassword={changePassword} />
}

export default ChangePasswordPageWrapper
