/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconShopify = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-shopify'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 64 64"
      data-testid={dataTestId}
    >
      <path
        d="M53.137 12.484c-.047-.283-.278-.5-.564-.527-.225-.037-5.17-.376-5.17-.376l-3.77-3.77c-.34-.376-1.092-.266-1.376-.188-.037 0-.752.225-1.922.605-1.137-3.29-3.15-6.306-6.696-6.306h-.303C32.32.605 31.076 0 30.026 0c-8.256.037-12.19 10.333-13.434 15.594l-5.77 1.77c-1.77.564-1.835.605-2.073 2.293L3.882 57.175 40.35 64l19.763-4.26c0-.037-6.94-46.897-6.976-47.255zM38.313 8.86c-.917.303-1.963.605-3.09.945v-.68a15.03 15.03 0 0 0-.752-4.999c1.848.284 3.09 2.357 3.843 4.733zm-6.068-4.298c.603 1.778.883 3.65.826 5.527v.34l-6.375 1.963c1.248-4.66 3.55-6.962 5.55-7.83zm-2.45-2.293a1.94 1.94 0 0 1 1.055.339c-2.66 1.238-5.472 4.366-6.678 10.627l-5.045 1.546C20.55 10.03 23.87 2.26 29.792 2.26z"
        fill="#95bf47"
      />
      <path
        d="M52.573 11.957c-.225-.037-5.17-.376-5.17-.376l-3.77-3.77c-.14-.142-.33-.223-.527-.225L40.354 64l19.763-4.26-6.98-47.218a.68.68 0 0 0-.564-.564z"
        fill="#5e8e3e"
      />
      <path
        d="M33.64 22.89l-2.454 7.242c-1.483-.718-3.104-1.104-4.752-1.133-3.848 0-4.036 2.412-4.036 3.018 0 3.298 8.636 4.564 8.636 12.333 0 6.11-3.885 10.03-9.1 10.03-6.26 0-9.467-3.885-9.467-3.885l1.665-5.55s3.28 2.83 6.073 2.83a2.47 2.47 0 0 0 2.564-2.49c0-4.34-7.09-4.527-7.09-11.618 0-5.962 4.298-11.77 12.934-11.77 3.394.05 5.018.99 5.018.99z"
        fill="#fff"
      />{' '}
    </svg>
  )
}

export default IconShopify
