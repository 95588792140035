/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import useWebsiteMutation from '@globo/react/useWebsiteMutation.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import languages from '@stiloso/shared/languages.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import uuid from '@monorepo/shared/uuid.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import Button from '@stiloso/components/Button.js'
import { useTranslation } from '@multilocale/react/index.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import PasteScriptSetup from '../components/PasteScriptSetup.js'

export const paths = ['/websites/new']

const NewWebsitePage = () => {
  useLoggedInSession()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { mutate: mutateWebsite, isError, error, reset } = useWebsiteMutation()
  let [name, setName] = useState('')
  const language = getBrowserLanguage()
  const [selectedLanguage, setSelectedLanguage] = useState(language)
  const _id = uuid()

  const languages_ = Object.keys(languages).map(languageCode => ({
    code: languageCode,
    name: languages[languageCode],
  }))

  const changeName = event => {
    let name = event.target.value

    name = name.toLowerCase()
    name = name.replaceAll(' ', '-')

    setName(name)
  }

  const createWebsite = async event => {
    event.preventDefault()

    try {
      const website = {
        _id,
        name: name.replace(/^-+|-+$/g, ''),
        defaultLocale: selectedLanguage,
        locales: [selectedLanguage],
      }

      mutateWebsite(website, {
        onSuccess: () => navigate('/websites'),
      })
    } catch (error) {
      // TO DO
    }
  }

  return (
    <Layout title={t('New website')}>
      <div css={section}>
        <div css={sectionTitle}>{t('New website')}</div>
        <form onSubmit={createWebsite} css={card}>
          <label htmlFor="nameInput" css={label}>
            {t('Website name')}
          </label>
          <input
            id="nameInput"
            css={input}
            value={name}
            onChange={changeName}
            required
            minLength="1"
            autoFocus
            autoComplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
          <LanguageAutocomplete
            inputLabel={t('Default language')}
            languages={languages_}
            selectedLanguage={selectedLanguage}
            onSelectLanguage={setSelectedLanguage}
          />
          <Button type="submit" css={{ marginTop: 16 }} fullWidth>
            {t('Add website')}
          </Button>
        </form>
        <div css={sectionTitle}>{t('Setup')}</div>
        <PasteScriptSetup websiteId={_id} dark />
      </div>
      {isError && <ErrorDialog error={error} close={() => reset()} />}
    </Layout>
  )
}

export default NewWebsitePage
