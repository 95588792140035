/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useState } from 'react'
import { jsx } from '@emotion/react'
import useUser from '@globo/react/useUser.js'
import useUserMutation from '@globo/react/useUserMutation.js'
import section from '@stiloso/styles/section.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import getImageFromEvent from '@monorepo/shared/getImageFromEvent.js'
import useUploadAvatarMutation from '@globo/react/useUploadAvatarMutation.js'
import { useTranslation } from '@multilocale/react/index.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import languages from '@stiloso/shared/languages.js'
import card from '@stiloso/styles/card.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Spinner from '@stiloso/components/Spinner.js'
import Button from '@stiloso/components/Button.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import UploadImageComponent from '@stiloso/components/UploadImage.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import getUserId from '../session/getUserId.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/account']

const UserSettings = () => {
  const { t } = useTranslation()
  const { mutate: mutateUser, isPending, reset, error } = useUserMutation()
  const { data: user } = useUser(getUserId())
  const uploadUserAvatar = useUploadAvatarMutation()
  const { language } = user
  const browserLanguage = getBrowserLanguage()

  const [selectedLanguage, setSelectedLanguage] = useState(
    language || browserLanguage,
  )

  const languages_ = Object.keys(languages).map(languageCode => ({
    code: languageCode,
    name: languages[languageCode],
  }))

  const changeUserSettings = event => {
    event.preventDefault()

    if (error) {
      reset()
    }

    const formData = new FormData(event.target)
    const firstName = formData.get('firstName')
    const lastName = formData.get('lastName')

    const updatedUser = {
      ...user,
      firstName,
      lastName,
      language: selectedLanguage,
      lastEditTime: new Date().getTime(),
    }

    mutateUser(updatedUser)
  }

  const deleteUserAvatar = () => {
    const userWithoutAvatar = {
      ...user,
      avatarUrl: null,
    }

    mutateUser(userWithoutAvatar)
  }

  const onClickUploadAvatar = () => {
    document.getElementById('uploadAvatarInput').click()
  }

  const uploadAvatar = async event => {
    let imageFile = await getImageFromEvent(event)
    uploadUserAvatar.mutate({ file: imageFile, userId: user._id })
  }

  return (
    <>
      <form onSubmit={changeUserSettings} css={[card, { margin: 16 }]}>
        <div>
          <label htmlFor="email" css={label}>
            {t('email')}
          </label>
          <input id="email" css={input} value={user.email} readOnly />
        </div>
        <div>
          <label htmlFor="firstName" css={label}>
            {t('First name')}
          </label>
          <input
            id="firstName"
            name="firstName"
            css={input}
            defaultValue={user.firstName}
          />
        </div>
        <div>
          <label htmlFor="lastName" css={label}>
            {t('Last name')}
          </label>
          <input
            id="lastName"
            name="lastName"
            css={input}
            defaultValue={user.lastName}
          />
        </div>
        <LanguageAutocomplete
          inputLabel="UI language"
          languages={languages_}
          selectedLanguage={selectedLanguage}
          onSelectLanguage={setSelectedLanguage}
        />
        <div css={{ width: 140 }}>
          <label css={label}>{t('Upload avatar')}</label>
          {user.avatarUrl && (
            <div css={{ position: 'relative', width: 128 }}>
              <ImageGraceful
                css={{
                  aspectRatio: '1 / 1',
                  height: 128,
                  borderRadius: 1000,
                }}
                retry={{
                  count: 10,
                  delay: 1,
                  accumulate: false,
                }}
                src={user.avatarUrl.replace('.jpg', '-256w.webp')}
                fallbackSrc={user.avatarUrl.replace('.jpg', '-256w.jpg')}
              />

              <div
                css={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: 4,
                  borderRadius: 4,
                  backgroundColor: 'var(--color-primary)',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: 'var(--color-primary-light)',
                  },
                  ':active': {
                    backgroundColor: 'var(--color-primary-dark)',
                  },
                }}
                onClick={deleteUserAvatar}
              >
                <IconDelete css={{ fill: 'white' }} />
              </div>
            </div>
          )}
          {!user.avatarUrl && (
            <UploadImageComponent
              inputId="uploadAvatarInput"
              onClickUploadInput={onClickUploadAvatar}
              uploadImage={uploadAvatar}
              isPending={uploadUserAvatar.isPending}
              styles={{ borderRadius: 1000 }}
            />
          )}
        </div>
        <Button
          type="submit"
          css={{ marginTop: 16 }}
          isLoading={isPending}
          fullWidth
        >
          {t('Save')}
        </Button>
      </form>

      {error && <ErrorDialog error={error} close={reset} />}
    </>
  )
}

const AccountPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Account')}</AppBarTitle>
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <UserSettings />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default AccountPage
