/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'

const titleStyle = css({
  color: 'white',
  height: 56,
  lineHeight: '56px',
  marginTop: '-16px',
  padding: '0 16px 0 16px',
  fontSize: '20px',
  letterSpacing: '1.61px',
  display: 'flex',
  alignItems: 'center',
})

const DrawerTitle = ({ children }) => <div css={titleStyle}>{children}</div>

export default DrawerTitle
