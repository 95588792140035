/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import Modal from '@stiloso/components/Modal.js'
import PasteScriptSetup from '../components/PasteScriptSetup.js'

const HtmlIntegrationDialog = ({ close, websiteId }) => (
  <Modal close={close} size="large">
    <div
      css={{
        padding: 24,
      }}
    >
      <PasteScriptSetup websiteId={websiteId} dark />
    </div>
  </Modal>
)

export default HtmlIntegrationDialog
