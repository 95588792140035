/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import addCampaign from '@globo/client/addCampaign.js'

export default function useCampaignMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: campaign => addCampaign(campaign),
    onSuccess: campaign => {
      queryClient.invalidateQueries({ queryKey: ['campaigns', campaign._id] })
    },
  })
}
