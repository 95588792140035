/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const tabs = css({
  display: 'grid',
  gridAutoFlow: 'column',
  minHeight: 48,
  borderBottom: '2px solid var(--color-background)',
  boxSizing: 'border-box',
})

const Tabs = ({ children, styles }) => (
  <div css={[tabs, styles]}>{children}</div>
)

export default Tabs
