/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getRoles() {
  let url = 'roles'

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get roles : ${error}`)
  })
}
