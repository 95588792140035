/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation } from '@tanstack/react-query'
import deleteDocument from '@globo/client/deleteDocument.js'

export default function useDeleteDocumentMutation() {
  return useMutation({
    mutationFn: articleId => deleteDocument(articleId),
    onError: error => error,
  })
}
