/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Button from './Button.js'
import Modal from './Modal.js'

const ErrorDialog = ({ close, error, title }) => {
  useEffect(() => {
    console.error(error)
  }, [error])

  const { t } = useTranslation()

  title ||= t('Error')
  let message = error?.toString?.()
  if (message?.trim().length > 0) {
    message = t(message)
  } else {
    message = null
  }

  return (
    <Modal close={close} size="small">
      <div css={{ fontWeight: 'bold', margin: 24, color: 'var(--color-base)' }}>
        {title}
      </div>
      <div
        css={{
          lineHeight: '26px',
          margin: 24,
        }}
      >
        {message}
      </div>
      <div
        css={{
          marginTop: 24,
          height: 2,
          backgroundColor: '#D8E0ED',
        }}
      />
      <div
        css={{
          margin: 24,
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Button appearance="secondary" onClick={close}>
          {t('Okay')}
        </Button>
      </div>
    </Modal>
  )
}

export default ErrorDialog
