/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx } from '@emotion/react'
import { useParams, useNavigate } from 'react-router-dom'
import uuid from '@monorepo/shared/uuid.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import draftjs from 'draft-js'
import useWebsite from '@globo/react/useWebsite.js'
import getDocument from '@globo/client/getDocument.js'
import { toast } from '@monorepo/shared/AndroidToast.js'
import slugify from '@monorepo/shared/slugify.js'
import addOrUpdateDocumentMutation from '@globo/react/useAddOrUpdateDocumentMutation.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import useDeleteDocumentMutation from '@globo/react/useDeleteDocument.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import Layout from '../components/Layout.js'

const { ContentState, Editor, EditorState } = draftjs

export const paths = ['/websites/:websiteName/docs/:documentId/write']

const WriteDocumentPage = () => {
  const { websiteName, documentId } = useParams()
  const navigate = useNavigate()
  const { data: website } = useWebsite(websiteName)

  const newDocumentId = documentId === 'new'

  const [titleEditorState, setTitleEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText('')),
  )

  const [slug, setSlug] = useState('')
  const [document, setDocument] = useState()

  const [contentEditorState, setContentEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText('')),
  )

  const { t } = useTranslation()

  const {
    mutate: mutateDocument,
    error,
    reset,
    isPending,
  } = addOrUpdateDocumentMutation()

  const {
    mutate: deleteDocumentMutation,
    isPending: deletingDocument,
    reset: resetDeleteDocumentMutation,
    isError: isErrorOnDeleteDocument,
    error: errorOnDeleteDocument,
  } = useDeleteDocumentMutation()

  const [deleteDialog, setDeleteDialog] = useState(false)

  const onChangeTitle = titleEditorState => {
    // setEdited(true)
    setTitleEditorState(titleEditorState)
  }

  const onChangeSlug = event => {
    // setEdited(true)
    let { value } = event.target
    value = slugify(value)
    setSlug(value)
  }

  const onChangeContent = contentEditorState => {
    // setEdited(true)
    setContentEditorState(contentEditorState)
  }

  const setStateWithDocument = document => {
    setTitleEditorState(
      EditorState.createWithContent(
        ContentState.createFromText(document.title || ''),
      ),
    )

    setSlug(document.slug)

    let contentEditorState = EditorState.createWithContent(
      ContentState.createFromText(document.content || ''),
    )

    setContentEditorState(contentEditorState)
    setDocument(document)
  }

  const onSaveDocument = () => {
    const title = titleEditorState.getCurrentContent().getPlainText()
    const content = contentEditorState.getCurrentContent().getPlainText()

    const updatedDocument = {
      ...document,
      title,
      slug,
      content,
      lastEditTime: new Date().toISOString(),
    }

    mutateDocument(
      { document: updatedDocument },
      {
        onSuccess: document => {
          setStateWithDocument(document)

          if (newDocumentId) {
            window.history.replaceState(
              null,
              null,
              `/websites/${websiteName}/docs/${document._id}/write`,
            )
          }

          toast(t('Saved'))
        },
      },
    )
  }

  const deleteDocument = () => {
    deleteDocumentMutation(document._id, {
      onSuccess: () => {
        setDeleteDialog(false)
        navigate(`/websites/${websiteName}/docs`)
      },
    })
  }

  useEffect(() => {
    if (!website) return

    if (newDocumentId) {
      let creationTime = new Date().toISOString()
      let lastEditTime = creationTime

      const _id = uuid()

      let document = {
        _id,
        originalDocumentId: _id,
        creationTime,
        lastEditTime,
        slug: '',
        title: '',
        websiteId: website._id || '',
        locale: website.defaultLocale || 'en',
      }

      setStateWithDocument(document)
    } else {
      getDocument(documentId)
        .then(document => {
          setStateWithDocument(document)
        })
        .catch(error => {
          console.log({ error })
          // setLoading(false)
          // setError(error)
        })
    }
  }, [website, documentId, newDocumentId])

  return (
    <Layout>
      <div css={{ width: '100%' }}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            css={{
              width: '100%',
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: 'center',
              padding: 16,
            }}
          >
            <div
              css={{
                display: 'grid',
                gridAutoColumns: '1fr',
                gridAutoFlow: 'column',
                gridTemplateAreas: `
                    'title slug'
                    'content content'
                  `,
                columnGap: '24px',
                width: '100%',
                maxWidth: '768px',
              }}
            >
              <div css={{ gridArea: 'title' }}>
                <label htmlFor="titleEditor" css={label}>
                  {t('Title')}
                </label>
                <div css={[input, { backgroundColor: 'white', padding: 0 }]}>
                  <Editor
                    id="titleEditor"
                    textAlignment="left"
                    editorState={titleEditorState}
                    onChange={onChangeTitle}
                  />
                </div>
              </div>
              <div css={{ gridArea: 'slug' }}>
                <label htmlFor="slug" css={label}>
                  {t('Slug')}
                </label>
                <input
                  id="slug"
                  name="slug"
                  css={[
                    input,
                    {
                      backgroundColor: 'white',
                      fontSize: '22px',
                    },
                  ]}
                  value={slug}
                  onChange={onChangeSlug}
                />
              </div>

              <div css={{ gridArea: 'content' }}>
                <div
                  css={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    marginBottom: -1.5,
                    marginTop: 16,
                  }}
                >
                  <label htmlFor="contentEditor" css={label}>
                    {t('Content')}
                  </label>
                </div>
                <div
                  id="contentEditorWrapper"
                  css={[
                    input,
                    {
                      lineHeight: 'normal',
                      fontSize: '18px',
                      fontWeight: 'initial',
                      backgroundColor: 'white',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    },
                  ]}
                >
                  <Editor
                    id="contentEditor"
                    textAlignment="left"
                    editorState={contentEditorState}
                    onChange={onChangeContent}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            css={{
              backgroundColor: 'white',
              height: '100vh',
              maxWidth: 360,
              width: '100%',
              position: 'sticky',
              right: 0,
              top: 0,
              padding: 16,
            }}
          >
            <Button onClick={onSaveDocument} fullWidth isLoading={isPending}>
              {t('Save')}
            </Button>
            <div
              css={{
                position: 'absolute',
                bottom: 0,
                padding: 16,
                width: '100%',
                left: 0,
              }}
            >
              <Button
                appearance="secondary"
                fullWidth
                onClick={() => setDeleteDialog(true)}
              >
                <IconDelete css={{ marginRight: 8, verticalAlign: 'bottom' }} />
                {t('Delete document')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {deleteDialog && (
        <DeleteDialog
          title={t('Warning')}
          message={t(
            'Deleting an document will delete all of its translations. Are you sure you want to delete?',
          )}
          close={() => setDeleteDialog(false)}
          onDelete={deleteDocument}
          loading={deletingDocument}
        />
      )}
      {isErrorOnDeleteDocument && (
        <ErrorDialog
          error={errorOnDeleteDocument}
          close={resetDeleteDocumentMutation}
        />
      )}
      {error && <ErrorDialog error={error} close={() => reset()} />}
    </Layout>
  )
}

export default WriteDocumentPage
