/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getWebsites() {
  let url = 'websites'

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get websites : ${error}`)
  })
}
