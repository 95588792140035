/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'

const AppBar = ({ children, style }) => (
  <div
    css={[
      {
        color: 'white',
        width: '100%',
        minHeight: 72,
        display: 'flex',
        gridArea: 'appBar',
        paddingLeft: 16,
        paddingRight: 16,
      },
      style,
    ]}
  >
    {children}
  </div>
)

export default AppBar
