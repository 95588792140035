/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useRef, useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import useDocuments from '@globo/react/useDocuments.js'
import languages2DefaultCountries from '@monorepo/shared/languages2DefaultCountries.js'
import { useTranslation } from '@multilocale/react/index.js'
import clickable from '@stiloso/styles/clickable.js'
import Button from '@stiloso/components/Button.js'
import useCurrentCustomer from '../queries/useCurrentCustomer.js'
import useCurrentWebsite from '../queries/useCurrentWebsite.js'

const suggestionsContainer = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  bottom: 64,
  padding: '16px 0px',
  width: '100%',
  backgroundColor: 'white',
  borderBottom: '2px solid var(--color-background)',
  borderLeft: '2px solid var(--color-background)',
  borderRight: '2px solid var(--color-background)',
  outline: 'none',
})

const ConversationsDocumentsPanel = ({
  showDocs,
  setShowDocs,
  addDocumentInMessage,
  inputRef,
}) => {
  let { t, language } = useTranslation()
  const website = useCurrentWebsite()
  const currentCustomer = useCurrentCustomer()
  let operatorLanguage = language || 'en'
  let customerLanguage = currentCustomer?.language || 'en'
  let locales = [operatorLanguage, customerLanguage]
  locales = [...new Set(locales)]
  let {
    data: documents,
    error,
    refetch,
  } = useDocuments(
    {
      websiteId: website._id,
      locales,
      fields: ['_id', 'title', 'locale', 'slug', 'slugLocalized'],
    },
    { enabled: !!showDocs, throwOnError: false },
  )
  const [focusedDocument, setFocusedDocument] = useState(null)
  const docsRef = useRef(null)

  let slug2documents = documents.reduce((slug2documents, document) => {
    if (!slug2documents[document.slug]) {
      slug2documents[document.slug] = []
    }

    slug2documents[document.slug].push(document)

    return slug2documents
  }, {})

  documents = Object.keys(slug2documents)
    .sort()
    .map(slug => {
      let operatorDocument =
        slug2documents[slug].find(
          ({ locale }) => locale === operatorLanguage,
        ) || slug2documents[slug][0]

      let customerDocument =
        slug2documents[slug].find(
          ({ locale }) => locale === customerLanguage,
        ) || slug2documents[slug][0]

      let document = {
        ...operatorDocument,
        titleOperator: operatorDocument?.title,
        slugLocalized:
          customerDocument?.slugLocalized || customerDocument?.slug,
        title: customerDocument?.title,
      }

      let flag = languages2DefaultCountries[customerDocument.locale]

      let url =
        website?.whitelabelDocsUrl ||
        `https://${website?.name}.globo.support/docs`

      if (!url.endsWith('/')) {
        url += '/'
      }

      url += `${customerDocument.locale}/${document.slugLocalized}/`

      return {
        ...document,
        flag,
        url,
      }
    })

  useEffect(() => {
    setFocusedDocument(documents?.length && documents[0]._id)
    docsRef.current.focus()
  }, [documents])

  useEffect(() => {
    function handleClickOutside(event) {
      if (docsRef && !docsRef.current.contains(event.target)) {
        setShowDocs(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [setShowDocs])

  const handleKeyDown = event => {
    const currentDocumentIndex = documents.findIndex(
      item => item._id === focusedDocument,
    )

    switch (event.key) {
      case 'Backspace': {
        event.preventDefault()
        setShowDocs(false)
        inputRef.current.focus()
        break
      }

      case 'ArrowUp': {
        event.preventDefault()
        if (documents.length) {
          const previousIndex =
            (currentDocumentIndex - 1 + documents.length) % documents.length
          setFocusedDocument(documents[previousIndex]._id)
        }

        break
      }

      case 'ArrowDown': {
        event.preventDefault()
        if (documents.length) {
          const nextIndex = (currentDocumentIndex + 1) % documents.length
          setFocusedDocument(documents[nextIndex]._id)
        }

        break
      }

      case 'Enter':
        event.preventDefault()

        if (documents.length) {
          addDocumentInMessage(documents[currentDocumentIndex])
        }

        break
      case 'Escape':
        setShowDocs(false)
        inputRef.current.focus()
        break
      default:
        break
    }
  }

  return (
    <div
      css={suggestionsContainer}
      role="listbox"
      tabIndex="0"
      aria-labelledby="inputLabel"
      onKeyDown={handleKeyDown}
      ref={docsRef}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          paddingTop: 0,
          paddingBottom: 16,
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 14,
        }}
      >
        <div css={{ marginLeft: 8 }}>{t('Docs')}</div>
        <div>
          <span css={{ marginRight: 16 }}>
            ↕{' '}
            <span css={{ marginLeft: 2, fontWeight: '200' }}>
              {t('to navigate')}
            </span>
          </span>
          <span css={{ marginRight: 16 }}>
            ⏎{' '}
            <span css={{ marginLeft: 2, fontWeight: '200' }}>
              {t('to select')}
            </span>
          </span>
          <span css={{ marginRight: 16 }}>
            esc{' '}
            <span css={{ marginLeft: 2, fontWeight: '200' }}>
              {t('to exit')}
            </span>
          </span>
        </div>
      </div>
      <div
        css={{
          height: 2,
          background: 'var(--color-background)',
          marginBottom: 16,
        }}
      />
      <div css={{ maxHeight: 280, overflowY: 'auto' }}>
        {!error &&
          documents.length > 0 &&
          documents.map(document => (
            <div
              id={document._id}
              key={document._id}
              css={[
                { padding: 8, display: 'flex' },
                clickable,
                focusedDocument === document._id && {
                  backgroundImage: `linear-gradient(
                              var(--color-hover, rgba(0, 0, 0, 0.1)),
                              var(--color-hover, rgba(0, 0, 0, 0.1))
                            )`,
                },
              ]}
              onClick={() => addDocumentInMessage(document)}
            >
              <img
                css={{
                  borderRadius: 48,
                  width: 20,
                  height: 20,
                  border: '2px solid white',
                  visibility: document.flag ? 'auto' : 'hidden',
                }}
                src={`https://assets.waiterio.com/flags/${document.flag?.toLowerCase()}.svg`}
              />
              <span css={{ margin: 'auto 8px' }}>
                {document?.titleOperator}
              </span>
            </div>
          ))}
        {!error && !documents.length && (
          <div css={{ padding: 16 }}>{t('No documents found')}</div>
        )}
        {error && (
          <div css={{ padding: 16 }}>
            <span>{error?.message}</span>
            <div css={{ marginTop: 8 }}>
              <Button appearance="success" onClick={refetch}>
                {t('Retry')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ConversationsDocumentsPanel
