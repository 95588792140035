/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconPerson = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-person'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M12 12c2.208 0 4.008-1.8 4.008-4.008s-1.8-3.984-4.008-3.984-4.008 1.776-4.008 3.984c0 2.208 1.8 4.008 4.008 4.008zM12 13.992c-2.664 0-7.992 1.344-7.992 4.008v1.992h15.984v-1.992c0-2.664-5.328-4.008-7.992-4.008z" />
    </svg>
  )
}

export default IconPerson
