/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import useConversations from '@globo/react/useConversations.js'
import useCurrentWebsite from './useCurrentWebsite.js'

const useCurrentConversation = () => {
  const [searchParams] = useSearchParams()
  const conversationId = searchParams.get('conversationId')
  const website = useCurrentWebsite()
  let { data: conversations } = useConversations(
    {
      websiteId: website?._id,
      sortField: 'lastEditTime',
      sortDirection: 'DESC',
      limit: 100,
    },
    {
      enabled: !!website._id,
    },
  )

  let currentConversationId = conversationId || conversations[0]?._id

  let currentConversation = conversations.find(
    conversation => conversation._id === currentConversationId,
  )
  return currentConversation
}

export default useCurrentConversation
