/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import updateWebsite from '@globo/client/updateWebsite.js'

export default function useUpdateWebsiteMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: website => updateWebsite(website),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['websites'] })
    },
  })
}
