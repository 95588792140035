/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable prefer-destructuring */

export default function getNameFromDomain(domain) {
  let name = domain
  let splitArray = name.split('.')
  let arrayLength = splitArray.length

  if (arrayLength > 2) {
    name = splitArray[arrayLength - 2] + '.' + splitArray[arrayLength - 1]
    if (
      splitArray[arrayLength - 2].length === 2 &&
      splitArray[arrayLength - 1].length === 2
    ) {
      name = splitArray[arrayLength - 3] + '.' + name
    }
  }
  return name.split('.')[0]
}
