/* Copyright 2013 - 2024 Waiterio LLC */
import React, { useEffect, useState } from 'react'
import identify from '../identify.js'
import rehydrateSession from './rehydrateSession.js'

const SessionRehydrator = ({ children }) => {
  const [rehydrated, setRehydrated] = useState()

  useEffect(() => {
    rehydrateSession()
    identify()
    setRehydrated(true)
  }, [])

  return rehydrated ? children : <div>loading</div>
}

export default SessionRehydrator
