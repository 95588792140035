/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import addRole from '@globo/client/addRole.js'

export default function useAddRoleMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: role => addRole(role),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles'] })
    },
  })
}
