/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import translateMessage from '@globo/client/translateMessage.js'

export default function useTranslateConversation(conversationId) {
  const queryClient = useQueryClient()

  return useMutation({
    key: 'translate-message',
    mutationFn: body => translateMessage(body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['translate-conversation', { conversationId }],
      })
    },
  })
}
