/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css([
  {
    label: 'card',
    backgroundColor: 'white',
    background: 'white',
    borderRadius: 4,
    padding: '0 16 16 16',
    display: 'inherit',
  },
])
