/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { memo, Suspense, useCallback, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useParams, Link } from 'react-router-dom'
import Tabs from '@stiloso/components/Tabs.js'
import Tab, { tab, tabText, selectedTab } from '@stiloso/components/Tab.js'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'
import useConversations from '@globo/react/useConversations.js'
import useConversationMutation from '@globo/react/useUpdateConversationMutation.js'
import usePresence from '@globo/react/usePresence.js'
import useWebsite from '@globo/react/useWebsite.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconAngleRight from '@stiloso/icons/IconAngleRight.js'
import IconDone from '@stiloso/icons/IconDone.js'
import IconPerson from '@stiloso/icons/IconPerson.js'
import Spinner from '@stiloso/components/Spinner.js'
import { toast } from '@monorepo/shared/AndroidToast.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import TimeAgo from '@stiloso/components/TimeAgo.js'
import useCurrentConversationId from '../queries/useCurrentConversationId.js'
import useHover from '../hooks/useHover.js'
import ErrorBoundary from '../components/ErrorBoundary.js'

const selectedExtraTab = css({
  boxShadow: 'inset 4px 0px 0px 0px var(--color-primary-dark)',
  backgroundColor: 'var(--color-background)',
})

const FiltersDropdown = memo(({ filter, onClick }) => {
  const { t } = useTranslation()
  const [hoverRef, isHovered] = useHover()
  const filters = ['mentions', 'resolved']

  return (
    <div
      css={[tab, isHovered && selectedTab, { position: 'relative' }]}
      ref={hoverRef}
      type="button"
      aria-label={t('Filters')}
    >
      <span css={tabText}>
        {t('Filters')} <span css={{ fontSize: 12 }}>▼</span>
      </span>
      <div
        css={{
          position: 'absolute',
          background: 'white',
          border: '1px solid #dcdfe4',
          borderRadius: 4,
          display: isHovered ? 'flex' : 'none',
          flexDirection: 'column',
          top: 46,
          left: 0,
          width: 280,
          zIndex: 99999,
        }}
      >
        {filters.map(filterTab => (
          <button
            key={filterTab}
            type="button"
            aria-label={t(filterTab)}
            data-testid={`filter-${filterTab}`}
            onClick={() => onClick(filterTab)}
            css={[
              {
                fontSize: 14,
                padding: 16,
                textTransform: 'capitalize',
                color: 'var(--color-base)',
                background: 'transparent',
                border: 0,
                margin: 0,
                textAlign: 'left',
              },
              clickable,
              filter === filterTab && selectedExtraTab,
            ]}
          >
            {t(filterTab)}
          </button>
        ))}
      </div>
    </div>
  )
})

const ConversationComponent = memo(
  ({
    _id,
    onClick,
    country,
    customerId,
    customerAvatarUrl,
    customerNickname,
    lastMessage,
    resolved,
    isSelected,
    unreadMessageCount,
    lastEditTime,
    locale,
  }) => {
    const { websiteName } = useParams()
    let { data: customersPresent } = usePresence()
    // console.log({ customersPresent })
    const isPresent = customersPresent.includes(customerId)
    const color = convertStringToColor(_id)
    const initials =
      customerId !== customerNickname
        ? customerNickname?.[0]?.toUpperCase()
        : null

    const isSmartphone = window.innerWidth < 992
    const to = isSmartphone
      ? `/websites/${websiteName}/conversations/${_id}`
      : `/websites/${websiteName}/conversations?conversationId=${_id}`

    return (
      <Link
        to={to}
        css={[
          clickable,
          {
            display: 'flex',
            background: 'transparent',
            border: 'none',
            margin: 0,
            textAlign: 'left',
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            padding: 16,
            borderBottom: '2px solid var(--color-background)',
          },
          isSelected && { background: 'var(--color-background)' },
        ]}
        onClick={() => onClick(_id)}
        type="button"
        aria-label={customerNickname}
      >
        <div
          css={{
            width: '100%',
            display: 'flex',
            flex: 1,
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 48,
              width: 48,
              minWidth: 48,
              borderRadius: 48,
              color: 'white',
              fontSize: 28,
              marginRight: 16,
              background: color,
              opacity: 0.75,
              textTransform: 'capitalize',
              position: 'relative',
            }}
          >
            {customerAvatarUrl && (
              <img
                css={{ width: '100%', height: '100%', borderRadius: 48 }}
                src={customerAvatarUrl}
              />
            )}
            {!customerAvatarUrl && initials}
            {!customerAvatarUrl && !initials && (
              <IconPerson css={{ fill: 'white' }} />
            )}
            {isPresent && (
              <div
                css={{
                  backgroundColor: isPresent
                    ? 'var(--color-green)'
                    : 'var(--color-red)',
                  borderRadius: 48,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: 12,
                  height: 12,
                  border: '2px solid white',
                }}
              />
            )}
            {country && (
              <img
                css={{
                  borderRadius: 48,
                  position: 'absolute',
                  bottom: -4,
                  right: -4,
                  width: 20,
                  height: 20,
                  border: '2px solid white',
                }}
                src={`https://assets.waiterio.com/flags/${country.toLowerCase()}.svg`}
              />
            )}
          </div>
          <div
            css={{
              flexGrow: 1,
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <h4 css={[singleLine, { marginBottom: 8 }]}>{customerNickname}</h4>
            <p
              css={{
                maxHeight: 40,
                overflow: 'hidden',
                lineHeight: '20px',
                hyphens: 'auto',
                marginTop: 0,
                marginBottom: 0,
                marginRight: 8,
                fontSize: 16,
              }}
            >
              {lastMessage}
            </p>
          </div>
          <div css={{ flexDirection: 'column' }}>
            <TimeAgo
              date={new Date(lastEditTime)}
              locale={locale}
              css={{
                fontWeight: '300',
                fontSize: 12,
                textAlign: 'center',
                marginBottom: 8,
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            />
            {unreadMessageCount > 0 && (
              <div
                css={{
                  width: 24,
                  height: 24,
                  fontSize: 12,
                  fontWeight: 'bold',
                  backgroundColor: 'var(--color-secondary)',
                  color: 'white',
                  borderRadius: 1000,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                }}
              >
                {unreadMessageCount}
              </div>
            )}
            {resolved && !unreadMessageCount && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 24,
                  height: 24,
                  backgroundColor: 'var(--color-green)',
                  borderRadius: 1000,
                  marginLeft: 'auto',
                }}
              >
                <IconDone css={{ fill: 'white' }} width={18} height={18} />
              </div>
            )}
            {!resolved && !unreadMessageCount && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 24,
                  height: 24,
                  backgroundColor: 'var(--color-red)',
                  borderRadius: 1000,
                  marginLeft: 'auto',
                }}
              >
                <IconAngleRight
                  css={{ fill: 'white' }}
                  width={16}
                  height={16}
                />
              </div>
            )}
          </div>
        </div>
      </Link>
    )
  },
)

const ConversationsPanel = ({ filter }) => {
  const { t } = useTranslation()
  const { websiteName } = useParams()

  const { data: website } = useWebsite(websiteName)

  let { data: conversations } = useConversations(
    {
      websiteId: website?._id,
      sortField: 'lastEditTime',
      sortDirection: 'DESC',
      limit: 100,
    },
    {
      enabled: !!website._id,
    },
  )

  let currentConversationId = useCurrentConversationId()

  const { mutate: mutateConversation } = useConversationMutation({
    websiteId: website?._id,
    sortField: 'lastEditTime',
    sortDirection: 'DESC',
  })

  let unreadConversations = conversations.filter(
    conversation => conversation.unreadMessageCount,
  )

  let unresolvedConversations = conversations.filter(
    conversation => !conversation.resolved,
  )

  let mentionedConversations = conversations.filter(
    conversation => conversation.currentUserMentioned,
  )

  let resolvedConversations = conversations.filter(
    conversation => conversation.resolved,
  )

  const onClickConversation = useCallback(
    _id => {
      const lastReadTime = new Date().toISOString()
      const currentConversation = conversations.find(
        conversation => conversation._id === _id,
      )
      const newConversation = { ...currentConversation, lastReadTime }
      mutateConversation(newConversation, {
        onError: error => {
          console.error(error)
          toast(t(error?.message))
        },
      })
    },
    [conversations, mutateConversation],
  )

  return (
    <div
      css={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 100,
      }}
    >
      {filter === 'all' &&
        conversations?.map(conversation => (
          <ConversationComponent
            key={conversation._id}
            onClick={onClickConversation}
            isSelected={currentConversationId === conversation._id}
            locale={website.defaultLocale}
            {...conversation}
          />
        ))}

      {filter === 'all' && !conversations.length && (
        <div css={{ margin: 16, textAlign: 'center' }}>
          {t('No conversation found')}
        </div>
      )}

      {filter === 'unread' &&
        unreadConversations?.map(conversation => (
          <ConversationComponent
            key={conversation._id}
            onClick={onClickConversation}
            isSelected={currentConversationId === conversation._id}
            {...conversation}
          />
        ))}

      {filter === 'unread' && !unreadConversations.length && (
        <div css={{ margin: 16, textAlign: 'center' }}>
          {t('No conversation found')}
        </div>
      )}

      {filter === 'unresolved' &&
        unresolvedConversations?.map(conversation => (
          <ConversationComponent
            key={conversation._id}
            onClick={onClickConversation}
            isSelected={currentConversationId === conversation._id}
            {...conversation}
          />
        ))}

      {filter === 'unresolved' && !unresolvedConversations.length && (
        <div css={{ margin: 16, textAlign: 'center' }}>
          {t('No conversation found')}
        </div>
      )}

      {filter === 'resolved' &&
        resolvedConversations?.map(conversation => (
          <ConversationComponent
            key={conversation._id}
            onClick={onClickConversation}
            isSelected={currentConversationId === conversation._id}
            {...conversation}
          />
        ))}

      {filter === 'resolved' && !resolvedConversations.length && (
        <div css={{ margin: 16, textAlign: 'center' }}>
          {t('No conversation found')}
        </div>
      )}

      {filter === 'mentions' &&
        mentionedConversations?.map(conversation => (
          <ConversationComponent
            key={conversation._id}
            onClick={onClickConversation}
            isSelected={currentConversationId === conversation._id}
            {...conversation}
          />
        ))}

      {filter === 'mentions' && !mentionedConversations.length && (
        <div css={{ margin: 16, textAlign: 'center' }}>
          {t('No conversation found')}
        </div>
      )}
    </div>
  )
}

const ConversationsPanelWrapper = () => {
  const { t } = useTranslation()
  const filterTabs = ['all', 'unread', 'unresolved']
  const [filter, setFilter] = useState(filterTabs[2])

  const changeFilter = useCallback(filter => {
    setFilter(filter)
  }, [])
  return (
    <div
      css={{
        background: 'white',
        height: '100%',
        '@media (min-width: 992px)': {
          width: 360,
        },
        '@media (max-width: 992px)': {
          width: '100%',
        },
      }}
    >
      <Tabs>
        {filterTabs.map(filterTab => (
          <Tab
            key={filterTab}
            onClick={() => changeFilter(filterTab)}
            selected={filter === filterTab}
            dataTestId={`filter-${filterTab}`}
          >
            {t(filterTab)}
          </Tab>
        ))}
        <FiltersDropdown filter={filter} onClick={changeFilter} />
      </Tabs>
      <ErrorBoundary>
        <Suspense fallback={<Spinner css={{ display: 'flex', flexGrow: 1 }} />}>
          <ConversationsPanel filter={filter} />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default ConversationsPanelWrapper
