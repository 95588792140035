/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getCampaigns from '@globo/client/getCampaigns.js'

export default function useCampaigns(parameters, options) {
  return useQuery({
    queryKey: ['campaigns', { ...parameters }],
    queryFn: () => getCampaigns(parameters),
    ...options,
  })
}
