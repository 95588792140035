/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import isLoggedInSession from '../session/isLoggedInSession.js'

export const paths = ['/']

const LoginRedirect = () => {
  const [searchParams] = useSearchParams()

  if (isLoggedInSession()) {
    return <Navigate exact to="/websites" />
  } else {
    return <Navigate exact to={`/login?${searchParams}`} />
  }
}

export default LoginRedirect
