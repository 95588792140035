/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getCampaign from '@globo/client/getCampaign.js'

export default function useCampaign(campaignId, options) {
  return useQuery({
    queryKey: ['campaigns', campaignId],
    queryFn: () => getCampaign(campaignId),
    ...options,
  })
}
