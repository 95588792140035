/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import getPusher from './getPusher.js'
import playChimeBellDing from './playChimeBellDing.js'

export default function useAddMessageSubscription(websiteId) {
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (websiteId) {
      let channelName = `website_${websiteId}`
      let eventType = 'MESSAGE_ADDED_EVENT'
      const channel = getPusher().subscribe(channelName)

      channel.bind(eventType, event => {
        queryClient.invalidateQueries({
          queryKey: ['messages', { conversationId: event.conversationId }],
        })

        queryClient.invalidateQueries({
          queryKey: ['conversations'],
        })

        queryClient.invalidateQueries({
          queryKey: ['translate-conversation'],
        })
        playChimeBellDing()
      })

      return () => {
        channel.unbind(eventType)
        getPusher().unsubscribe(channelName)
        // console.log(
        //   `pusher unsubscribe channel ${channelName} event ${eventType}`,
        // )
      }
    }
  }, [websiteId])
}
