/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getCampaign(campaignId) {
  let url = `campaigns/${campaignId}`

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get campaign ${campaignId} : ${error}`)
  })
}
