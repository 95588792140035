/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import uuid from '@monorepo/shared/uuid.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import Button from '@stiloso/components/Button.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import listItem from '@stiloso/styles/listItem.js'
import useCampaignMutation from '@globo/react/useCampaignMutation.js'
import useCampaign from '@globo/react/useCampaign.js'
import useUser from '@globo/react/useUser.js'
import useWebsite from '@globo/react/useWebsite.js'
import useCustomers from '@globo/react/useCustomers.js'
import draftjs from 'draft-js'
import useSendCampaignMutation from '@globo/react/useSendCampaignMutation.js'
import LoadingDialog from '@stiloso/components/LoadingDialog.js'
import Spinner from '@stiloso/components/Spinner.js'
import debounce from '@monorepo/shared/debounce.js'
import link from '@stiloso/styles/link.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import getUserId from '../session/getUserId.js'

export const paths = ['/websites/:websiteName/campaigns/:campaignId']

const { ContentState, Editor, EditorState } = draftjs

const checkbox = css`
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1em;
  height: 1em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
`

const CampaignEditor = () => {
  const { t } = useTranslation()
  const { websiteName, campaignId } = useParams()
  const newCampaign = campaignId === 'new'

  const { data: campaign } = useCampaign(campaignId, {
    enabled: !newCampaign,
  })
  const { data: website } = useWebsite(websiteName)

  const { data: customers } = useCustomers({ websiteId: website._id })

  let [selectedRecipients, setSelectedRecipients] = useState(
    campaign?.recipients || [],
  )
  const { data: currentUser } = useUser(getUserId())
  let [name, setName] = useState(campaign?.name || '')
  const {
    mutate: mutateCampaign,
    isPending: savingCampaign,
    error: addCampaignError,
    reset: resetCampaignMutation,
  } = useCampaignMutation()
  const {
    mutate: mutateSendCampaign,
    isPending: sendCampaignLoading,
    error: sendCampaignError,
    reset: resetSendCampaign,
  } = useSendCampaignMutation()
  const [edited, setEdited] = useState(false)
  const [sendCampaignLoadingMessage, setSendCampaignLoadingMessage] =
    useState(null)

  const [subjectEditorState, setSubjectEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(campaign?.subject || ''),
    ),
  )

  const [contentEditorState, setContentEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(campaign?.content || ''),
    ),
  )

  const sendTestCampaign = () => {
    setSendCampaignLoadingMessage(t('Sending test campaign'))
    const updatedCampaign = {
      ...campaign,
      recipients: [currentUser],
    }
    mutateSendCampaign(updatedCampaign)
  }

  const sendCampaign = event => {
    setSendCampaignLoadingMessage(
      t('Sending campaign to {{recipientsCount}} recipients').replace(
        '{{recipientsCount}}',
        campaign.recipients.length,
      ),
    )

    mutateSendCampaign(campaign)
  }

  const onChangeSubject = newSubjectEditorState => {
    let oldSubject = subjectEditorState.getCurrentContent()
    let newSubject = newSubjectEditorState.getCurrentContent()

    if (oldSubject !== newSubject) {
      setSubjectEditorState(newSubjectEditorState)
      setEdited(true)
    }
  }

  const onChangeContent = newContentEditorState => {
    let oldContent = contentEditorState.getCurrentContent()
    let newContent = newContentEditorState.getCurrentContent()

    if (oldContent !== newContent) {
      setContentEditorState(newContentEditorState)
      setEdited(true)
    }
  }

  const changeName = event => {
    let name = event.target.value
    setName(name)
    setEdited(true)
  }

  const onClickRecipient = recipient => {
    const isRecipientAlreadyAdded = selectedRecipients.some(
      recipient_ => recipient._id === recipient_._id,
    )

    if (isRecipientAlreadyAdded) {
      setSelectedRecipients(prevState =>
        prevState.filter(recipient_ => recipient_._id !== recipient._id),
      )
    } else {
      setSelectedRecipients(prevState => [...prevState, recipient])
    }

    setEdited(true)
  }

  const saveCampaign = form => {
    try {
      const {
        name,
        subjectEditorState,
        contentEditorState,
        selectedRecipients,
      } = form
      const subject = subjectEditorState
        .getCurrentContent()
        .getPlainText()
        .trim()

      const content = contentEditorState.getCurrentContent().getPlainText()

      let updatedCampaign = {
        ...campaign,
        name,
        subject,
        content,
        recipients: selectedRecipients,
        lastEditTime: new Date().toISOString(),
      }

      if (newCampaign) {
        const _id = uuid()
        let creationTime = new Date().toISOString()
        let lastEditTime = creationTime

        updatedCampaign = {
          ...updatedCampaign,
          _id,
          websiteId: website._id,
          creationTime,
          lastEditTime,
        }
      }

      mutateCampaign(updatedCampaign, {
        onSuccess: newCampaign => {
          if (newCampaign) {
            window.history.replaceState(
              null,
              null,
              `/websites/${websiteName}/campaigns/${newCampaign._id}`,
            )
          }
          setEdited(false)
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const saveCampaignDebounced = debounce(1500, saveCampaign)

  useEffect(() => {
    if (edited) {
      saveCampaignDebounced({
        name,
        subjectEditorState,
        contentEditorState,
        selectedRecipients,
      })
    }
    return () => {
      saveCampaignDebounced.cancel()
    }
  }, [edited, name, subjectEditorState, contentEditorState, selectedRecipients])

  useEffect(() => {
    function beforeunload(event) {
      if (edited) {
        event.returnValue = 'The campaign changes will be lost. Are you sure?'
      }
    }

    window.addEventListener('beforeunload', beforeunload)

    return function cleanup() {
      window.removeEventListener('beforeunload', beforeunload)
    }
  }, [edited])

  return (
    <>
      <div css={{ padding: '0px 16px' }}>
        <div css={card}>
          <label htmlFor="nameInput" css={label}>
            {t('name')}
          </label>
          <input
            id="nameInput"
            css={input}
            value={name}
            onChange={changeName}
            required
            minLength="1"
            autoFocus
            autoComplete="off"
            data-lpignore="true"
            data-form-type="other"
          />

          <div>
            <label css={label}>{t('Email subject')}</label>
            <div css={[input, { backgroundColor: 'white', padding: 0 }]}>
              <Editor
                id="subjectEditor"
                textAlignment="left"
                editorState={subjectEditorState}
                onChange={onChangeSubject}
              />
            </div>
          </div>
          <div>
            <label htmlFor="contentEditor" css={label}>
              {t('Email content')}
            </label>
            <div
              id="contentEditorWrapper"
              css={[
                input,
                {
                  lineHeight: 'normal',
                  fontSize: '18px',
                  fontWeight: 'initial',
                  backgroundColor: 'white',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                },
              ]}
            >
              <Editor
                id="contentEditor"
                textAlignment="left"
                editorState={contentEditorState}
                onChange={onChangeContent}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            flex: 1,
            marginTop: 8,
          }}
        >
          <a
            css={link}
            href="https://www.globo.support/docs/en/how-to-use-variables-in-campaign-emails"
            target="_blank"
            rel="noreferrer"
          >
            How to use variables in email content?
          </a>
        </div>

        <Button
          fullWidth
          appearance="secondary"
          css={{ marginTop: 32 }}
          onClick={sendTestCampaign}
          isLoading={sendCampaignLoading || savingCampaign}
        >
          {t('Send to yourself')}{' '}
          <span css={{ textTransform: 'none' }}>({currentUser.email})</span>
        </Button>
        <div css={[sectionTitle, { marginTop: 16 }]}>{t('Recipients')}</div>
        {customers.length > 0 && (
          <>
            <div
              css={{
                maxHeight: 400,
                overflow: 'auto',
                borderRadius: 4,
              }}
            >
              {customers.map(
                (customer, index) =>
                  customer.email && (
                    <div
                      key={customer._id}
                      css={[
                        listItem,
                        {
                          lineHeight: '30px',
                          padding: 16,
                          display: 'flex',
                        },
                      ]}
                      onClick={() => onClickRecipient(customer)}
                    >
                      <div
                        css={{
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          marginRight: 24,
                          marginLeft: 0,
                        }}
                      >
                        <input
                          type="checkbox"
                          css={checkbox}
                          checked={selectedRecipients.some(
                            recipient => recipient._id === customer._id,
                          )}
                          readOnly
                        />
                      </div>

                      <div>
                        <div css={{ fontWeight: '600' }}>
                          {customer.nickname || `visitor${index + 1}`}
                        </div>
                        <div css={{ fontWeight: '300', fontSize: 16 }}>
                          {customer.email}
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </div>
            <Button
              fullWidth
              appearance="success"
              css={{ marginTop: 16 }}
              onClick={sendCampaign}
              isLoading={sendCampaignLoading || savingCampaign}
            >
              {t('Send Campaign')}
            </Button>
          </>
        )}
      </div>
      {addCampaignError && (
        <ErrorDialog
          error={addCampaignError}
          close={() => resetCampaignMutation()}
        />
      )}
      {sendCampaignError && (
        <ErrorDialog
          error={sendCampaignError}
          close={() => resetSendCampaign()}
        />
      )}
      {sendCampaignLoading && (
        <LoadingDialog
          title={t('Sending campaign')}
          message={sendCampaignLoadingMessage}
        />
      )}
    </>
  )
}

const CampaignPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Campaign')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <CampaignEditor />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default CampaignPage
