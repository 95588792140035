/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: list;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdfe4;
  background-color: white;
  border-radius: 4px;
  margin: 16px 0px;
`
