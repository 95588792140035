/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getCustomer from '@globo/client/getCustomer.js'

export default function useCustomer(customerId, options) {
  return useQuery({
    queryKey: ['customers', customerId],
    queryFn: () => getCustomer(customerId),
    ...options,
  })
}
