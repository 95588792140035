/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import useWebsites from '@globo/react/useWebsites.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import IconBrand from '@globo/shared/IconBrand.js'

import useLoggedInSession from '../session/useLoggedInSession.js'
import PasteScriptSetup from '../components/PasteScriptSetup.js'

export const paths = ['/setup-guide']

const SetupGuidePage = () => {
  useLoggedInSession()

  const { data: websites } = useWebsites()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [website] = websites

  const goToWebsite = () => {
    navigate(`/websites/${website.name}/conversations`)
  }

  return (
    <div
      css={{
        minHeight: '100%',
        padding: '48px 0px',
        backgroundColor: 'var(--color-primary)',
      }}
    >
      <div
        css={{
          maxWidth: 1200,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '0px 16px',
          width: '100%',
          backgroundColor: 'var(--color-primary)',
        }}
      >
        <div css={{ padding: '0px 16px' }}>
          <IconBrand light />
        </div>

        <div
          css={{
            margin: '48px 0px',
            textAlign: 'center',
            color: 'white',
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: '24px',
          }}
        >
          {t('Setup guide')}
        </div>

        <div
          css={{
            margin: '16px auto',
            width: '100%',
            maxWidth: 580,
            padding: '24px',
            backgroundColor: 'var(--color-background)',
          }}
        >
          <PasteScriptSetup websiteId={website._id} dark />
          <div css={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
            <Button
              onClick={goToWebsite}
              css={{
                marginTop: 16,
                backgroundColor: 'var(--color-background)',
              }}
              appearance="secondary"
            >
              {t('Skip setup')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupGuidePage
