/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { Suspense } from 'react'
import { jsx, css } from '@emotion/react'
import Spinner from '@stiloso/components/Spinner.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import ConversationsPanel from './ConversationsPanel.js'
import ConversationsCustomerPanel from './ConversationsCustomerPanel.js'
import ConversationsMessagesPanel from './ConversationsMessagesPanel.js'

const ConversationsPage = () => {
  useLoggedInSession()

  return (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <ConversationsPanel />
            <div css={{ flexGrow: 1 }}>
              <ConversationsMessagesPanel />
            </div>

            <ErrorBoundary>
              <Suspense
                fallback={
                  <Spinner style={{ width: 320, background: 'white' }} />
                }
              >
                <ConversationsCustomerPanel />
              </Suspense>
            </ErrorBoundary>
          </div>
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default ConversationsPage
