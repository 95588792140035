/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import sendCampaign from '@globo/client/sendCampaign.js'

export default function useSendCampaignMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: campaign => sendCampaign(campaign),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['campaigns'] })
    },
  })
}
