/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconMoreVertical = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-more-vertical'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M12 7.992c1.104 0 1.992-0.888 1.992-1.992s-0.888-1.992-1.992-1.992-1.992 0.888-1.992 1.992 0.888 1.992 1.992 1.992zM12 10.008c-1.104 0-1.992 0.888-1.992 1.992s0.888 1.992 1.992 1.992 1.992-0.888 1.992-1.992-0.888-1.992-1.992-1.992zM12 16.008c-1.104 0-1.992 0.888-1.992 1.992s0.888 1.992 1.992 1.992 1.992-0.888 1.992-1.992-0.888-1.992-1.992-1.992z" />
    </svg>
  )
}

export default IconMoreVertical
