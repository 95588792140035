/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */

import React from 'react'

const IconFolders = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-folders'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M5 21 3 9h18l-2 12Zm1.675-2h10.65l1.275-8H5.4ZM10 15h4q.425 0 .713-.288Q15 14.425 15 14t-.287-.713Q14.425 13 14 13h-4q-.425 0-.712.287Q9 13.575 9 14t.288.712Q9.575 15 10 15ZM6 8q-.425 0-.713-.287Q5 7.425 5 7t.287-.713Q5.575 6 6 6h12q.425 0 .712.287Q19 6.575 19 7t-.288.713Q18.425 8 18 8Zm2-3q-.425 0-.713-.288Q7 4.425 7 4t.287-.713Q7.575 3 8 3h8q.425 0 .712.287Q17 3.575 17 4t-.288.712Q16.425 5 16 5ZM6.675 19h10.65Z" />
    </svg>
  )
}

export default IconFolders
