/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'

const Drawer = ({ children, top }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: 200,
      top: top || 0,
      paddingTop: 16,
      paddingBottom: 16,
      background: 'var(--color-primary)',
      color: '#eeeeee',
      gridArea: 'drawer',
      position: 'fixed',
    }}
  >
    {children}
  </div>
)

export default Drawer
