/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import singleLine from '@stiloso/styles/singleLine.js'

const modalHeaderWhite = css([
  singleLine,
  {
    textAlign: 'left',
    lineHeight: '48px',
    padding: '0 16px',
    color: 'var(--color-primary)',
    borderBottom: '1.5px solid var(--color-primary)',
  },
])

const ModalHeaderWhite = ({ children }) => (
  <div css={modalHeaderWhite}>{children}</div>
)

export default ModalHeaderWhite
