/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'

export default function usePresence(options) {
  return useQuery({
    queryKey: ['presence'],
    queryFn: () => Promise.resolve([]),
    ...options,
  })
}
