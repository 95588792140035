/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import updateConversation from '@globo/client/updateConversation.js'

export default function useUpdateConversationMutation(parameters) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: conversation => updateConversation(conversation),
    onMutate: async conversation => {
      await queryClient.cancelQueries({
        queryKey: ['conversations', { ...parameters }],
      })

      const previousConversations = queryClient.getQueryData([
        'conversations',
        { ...parameters },
      ])

      queryClient.setQueryData(
        ['conversations', { ...parameters }],
        oldConversations => {
          let indexToReplace = oldConversations?.findIndex(
            oldConversation => oldConversation._id === conversation._id,
          )
          let newConversations = [
            ...(oldConversations?.slice(0, indexToReplace) ?? []),
            conversation,
            ...(oldConversations?.slice(indexToReplace + 1) ?? []),
          ]

          return [...newConversations]
        },
      )

      return { previousConversations }
    },
    onError: context => {
      queryClient.setQueryData(
        ['conversations', { ...parameters }],
        context.previousConversations,
      )
    },
    onSettled: async () =>
      queryClient.invalidateQueries({
        queryKey: ['conversations', { ...parameters }],
      }),
  })
}
