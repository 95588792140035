/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconEmptyMail = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-empty-mail'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 -960 960 960"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="m480-920 362 216q18 11 28 30t10 40v434q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200v-434q0-21 10-40t28-30l362-216Zm0 466 312-186-312-186-312 186 312 186Zm0 94L160-552v352h640v-352L480-360Zm0 160h320-640 320Z" />
    </svg>
  )
}

export default IconEmptyMail
