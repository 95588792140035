/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getMessages from '@globo/client/getMessages.js'

export default function useMessages(parameters, options) {
  return useQuery({
    queryKey: ['messages', { ...parameters }],
    queryFn: () => getMessages(parameters),
    ...options,
  })
}
