/* Copyright 2013 - 2024 Waiterio LLC */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import clearSession from '../session/clearSession.js'

export const paths = ['/logout']

const LogoutPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)

      clearSession()

      navigate('/login')
    }
  })

  return <h1>Logout</h1>
}

export default LogoutPage
