/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import addWebsite from '@globo/client/addWebsite.js'

export default function useWebsiteMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: website => addWebsite(website),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['websites'] })
    },
  })
}
