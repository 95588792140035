/* Copyright 2013 - 2024 Waiterio LLC */
import getScreenWidth from './getScreenWidth.js'
import getUserAgent from './getUserAgent.js'
import getOsName from './getOsName.js'
import getOsVersion from './getOsVersion.js'

const getOs = params => {
  params ||= {}
  let { userAgent, screenWidth } = params
  screenWidth ||= getScreenWidth()
  userAgent ||= getUserAgent()

  return {
    name: getOsName({ userAgent, screenWidth }),
    version: getOsVersion({ userAgent }),
  }
}

export default getOs
