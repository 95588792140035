/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useParams, useNavigate } from 'react-router-dom'
import section from '@stiloso/styles/section.js'
import { useTranslation } from '@multilocale/react/index.js'
import SearchBar from '@stiloso/components/SearchBar.js'
import useWebsite from '@globo/react/useWebsite.js'
import useCustomers from '@globo/react/useCustomers.js'
import useConversationMutation from '@globo/react/useConversationMutation.js'
import fuzzySearch from '@monorepo/shared/fuzzySearch.js'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'
import unselectable from '@stiloso/styles/unselectable.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import getConversations from '@globo/client/getConversations.js'
import uuid from '@monorepo/shared/uuid.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconOpenInNewTab from '@stiloso/icons/IconOpenInNewTab.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import CustomerActionsDialog from '../dialogs/CustomerActionsDialog.js'

export const paths = ['/websites/:websiteName/customers']

export const listItem = css`
  ${clickable}
  ${singleLine}
  ${unselectable}
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
`

const CustomersPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { websiteName } = useParams()
  const { data: website } = useWebsite(websiteName)
  const { data: customers } = useCustomers({ websiteId: website._id })
  const {
    mutate: mutateConversation,
    error: conversationMutationError,
    reset: resetMutateConversation,
  } = useConversationMutation()
  const [filteredUsers, setFilteredUsers] = useState(null)
  const [customerActionsDialog, setCustomerActionsDialog] = useState(null)

  const onSearch = searchQuery => {
    if (searchQuery.length) {
      const filtered = fuzzySearch({
        query: searchQuery,
        list: customers,
        properties: ['nickname', 'email'],
      })
      setFilteredUsers(filtered)
    } else {
      setFilteredUsers(null)
    }
  }

  const onCancelSearch = () => {
    setFilteredUsers(null)
  }

  const openCustomerInNewTab = link => {
    if (link) {
      window.open(link, '_blank')
    }
  }

  const goToConversation = async customerId => {
    const conversations = await getConversations({
      customerId,
      websiteId: website._id,
      fields: ['_id'],
    })

    if (!conversations.length) {
      let creationTime = new Date().toISOString()
      let lastEditTime = creationTime
      let customer = customers.find(customer => customer._id === customerId)

      let newConversation = {
        _id: uuid(),
        creationTime,
        lastEditTime,
        organizationId: website.organizationId,
        customerId,
        customerNickname:
          customer?.nickname || customer?.email || customer?._id,
        websiteId: website._id,
      }
      mutateConversation(newConversation, {
        onSuccess: conversation => {
          navigate(
            `/websites/${website.name}/conversations/${conversation._id}`,
          )
        },
      })
    } else {
      navigate(
        `/websites/${website.name}/conversations/${conversations[0]._id}`,
      )
    }
  }

  const showCustomerActionsDialog = customer => {
    setCustomerActionsDialog({ customer })
  }

  const hideCustomerActionsDialog = () => {
    setCustomerActionsDialog(null)
  }

  return (
    <>
      <div css={[section, { paddingLeft: 16, paddingRight: 16 }]}>
        <SearchBar
          placeholder={t('Search')}
          onSearch={onSearch}
          onCancel={onCancelSearch}
        />
      </div>

      {filteredUsers?.length === 0 && (
        <div css={section}>{t('no customers found')}</div>
      )}

      {filteredUsers?.length > 0 && (
        <>
          <CustomersHeaderRow />

          {filteredUsers.map((customer, index) => (
            <CustomerRow
              index={index}
              customer={customer}
              openCustomerInNewTab={openCustomerInNewTab}
              showCustomerActionsDialog={showCustomerActionsDialog}
            />
          ))}
        </>
      )}

      {!filteredUsers && customers.length > 0 && (
        <>
          <CustomersHeaderRow />

          {customers.map((customer, index) => (
            <div key={customer._id}>
              <CustomerRow
                index={index}
                customer={customer}
                openCustomerInNewTab={openCustomerInNewTab}
                showCustomerActionsDialog={showCustomerActionsDialog}
              />
            </div>
          ))}
        </>
      )}
      {customerActionsDialog && (
        <CustomerActionsDialog
          customer={customerActionsDialog.customer}
          close={hideCustomerActionsDialog}
          goToConversation={goToConversation}
        />
      )}
      {conversationMutationError && (
        <ErrorDialog
          error={conversationMutationError}
          close={resetMutateConversation}
        />
      )}
    </>
  )
}

const CustomersHeaderRow = () => {
  const { t } = useTranslation()
  return (
    <>
      <div css={[dNone, dLgBlock]}>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '48px repeat(3, 1fr) 80px',
            marginTop: 16,
            marginBottom: 2,
            marginLeft: 16,
            marginRight: 16,
            fontWeight: 500,
            gridGap: 16,
            padding: 16,
            backgroundColor: 'white',
          }}
        >
          <div>#</div>
          <div>{t('Name')}</div>
          <div>{t('Email')}</div>
          <div>{t('Location')}</div>
          <div css={{ textAlign: 'right' }}>{t('Actions')}</div>
        </div>
      </div>
      <div css={dLgNone}>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 80px',
            marginTop: 16,
            marginBottom: 2,
            marginLeft: 16,
            marginRight: 16,
            fontWeight: 500,
            gridGap: 16,
            padding: 16,
            backgroundColor: 'white',
            fontSize: 16,
          }}
        >
          <div>{t('Email')}</div>
          <div css={{ textAlign: 'right' }}>{t('Actions')}</div>
        </div>
      </div>
    </>
  )
}

const CustomerRow = ({
  index,
  customer,
  showCustomerActionsDialog,
  openCustomerInNewTab,
}) => (
  <>
    <div css={[dNone, dLgBlock]}>
      <div
        css={[
          {
            display: 'grid',
            gridTemplateColumns: '48px repeat(3, 1fr) 80px',
            margin: '2px 16px',
            gridGap: 16,
            backgroundColor: 'white',
            padding: 16,
          },
          clickable,
        ]}
        onClick={() => openCustomerInNewTab(customer.metadata?.analytics)}
      >
        <div>{index + 1}</div>
        <div>{customer.nickname || customer._id}</div>
        <div>{customer.email}</div>
        <div>{customer.country || '?'}</div>
        <div
          css={[
            {
              backgroundColor: 'var(--color-primary)',
              textAlign: 'center',
              padding: 8,
              borderRadius: 4,
              width: 48,
              marginLeft: 'auto',
            },
            clickable,
          ]}
          onClick={event => {
            event.stopPropagation()
            showCustomerActionsDialog(customer)
          }}
        >
          <IconOpenInNewTab css={{ fill: 'white' }} />
        </div>
      </div>
    </div>
    <div css={dLgNone}>
      <div
        css={[
          {
            display: 'grid',
            gridTemplateColumns: '1fr 80px',
            margin: '2px 16px',
            gridGap: 16,
            backgroundColor: 'white',
            padding: 16,
            fontSize: 16,
          },
          clickable,
        ]}
        onClick={() => openCustomerInNewTab(customer.metadata?.analytics)}
      >
        <div css={[singleLine, { margin: 'auto 0px' }]}>{customer.email}</div>
        <div
          css={[
            {
              backgroundColor: 'var(--color-primary)',
              textAlign: 'center',
              padding: 8,
              width: 48,
              marginLeft: 'auto',
            },
            clickable,
          ]}
          onClick={event => {
            event.stopPropagation()
            showCustomerActionsDialog(customer)
          }}
        >
          <IconOpenInNewTab css={{ fill: 'white' }} />
        </div>
      </div>
    </div>
  </>
)

/* eslint-disable react/function-component-definition */
export default function CustomersPageWrapper() {
  return (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <CustomersPage />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}
