/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { Link, useParams } from 'react-router-dom'
import useCampaigns from '@globo/react/useCampaigns.js'
import useWebsite from '@globo/react/useWebsite.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import Spinner from '@stiloso/components/Spinner.js'
import { useTranslation } from '@multilocale/react/index.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/websites/:websiteName/campaigns']

let websiteCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
  },
])

const CampaignsList = () => {
  const { websiteName } = useParams()
  const { data: website } = useWebsite(websiteName)

  let { data: campaigns } = useCampaigns(
    { websiteId: website._id },
    { enabled: !!website },
  )

  return (
    <div
      css={{
        padding: 16,
        display: 'flex',
        direction: 'column',
        flexWrap: 'wrap',
      }}
    >
      {campaigns?.map(({ _id, name }) => (
        <Link
          key={name}
          css={websiteCard}
          to={`/websites/${websiteName}/campaigns/${_id}`}
        >
          {name}
        </Link>
      ))}
    </div>
  )
}

const CampaignsPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  const { websiteName } = useParams()

  return (
    <Layout>
      <div css={{ margin: '32px auto', maxWidth: 640, width: '100%' }}>
        <AppBar>
          <AppBarTitle>{t('Campaigns')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            label={t('New campaign')}
            to={`/websites/${websiteName}/campaigns/new`}
          />
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <CampaignsList />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default CampaignsPage
