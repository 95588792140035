/* Copyright 2013 - 2024 Waiterio LLC */
import getScreenWidth from './getScreenWidth.js'
import getUserAgent from './getUserAgent.js'
import isAndroid from './isAndroid.js'
import isiOS from './isiOS.js'
import isLinux from './isLinux.js'
import isMacOS from './isMacOS.js'
import isWindows from './isWindows.js'

const getOsName = params => {
  params ||= {}
  let { userAgent, screenWidth } = params
  screenWidth ||= getScreenWidth()
  userAgent ||= getUserAgent()
  let osName = 'unknown'

  if (isAndroid(userAgent, screenWidth)) {
    osName = 'Android'
  } else if (isiOS(userAgent, screenWidth)) {
    osName = 'iOS'
  } else if (isLinux(userAgent, screenWidth)) {
    osName = 'Linux'
  } else if (isMacOS(userAgent, screenWidth)) {
    osName = 'macOS'
  } else if (isWindows(userAgent, screenWidth)) {
    osName = 'Windows'
  }
  return osName
}

export default getOsName
