/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useParams } from 'react-router-dom'
import useWebsite from '@globo/react/useWebsite.js'

const useCurrentWebsite = () => {
  const { websiteName } = useParams()
  const { data: currentWebsite } = useWebsite(websiteName)
  return currentWebsite
}

export default useCurrentWebsite
