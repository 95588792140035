/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { Suspense, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import clickable from '@stiloso/styles/clickable.js'
import useUpdateWebsiteMutation from '@globo/react/useUpdateWebsiteMutation.js'
import useDocuments from '@globo/react/useDocuments.js'
import translateDocument from '@globo/client/translateDocument.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import useWebsite from '@globo/react/useWebsite.js'
import IconClose from '@stiloso/icons/IconClose.js'
import IconAdd from '@stiloso/icons/IconAdd.js'
import IconDone from '@stiloso/icons/IconDone.js'
import IconPerson from '@stiloso/icons/IconPerson.js'
import IconRobot from '@stiloso/icons/IconRobot.js'
import IconOpenInNewTab from '@stiloso/icons/IconOpenInNewTab.js'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import AddLanguageDialog from '@stiloso/components/AddLanguageDialog.js'
import Spinner from '@stiloso/components/Spinner.js'
import { toast } from '@monorepo/shared/AndroidToast.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

const tableContainer = css`
  margin: 16px;
  width: calc(100% - 32px);
`

const tableRow = css`
  padding: 14px;
  text-align: left;
`

const tableColumn = css`
  background: var(--color-background);
  padding-right: 4px;
  padding-bottom: 4px;
  text-align: left;
  vertical-align: middle;
  height: 48px;
  line-height: 48px;
  fill: #6a768a;
  color: #6a768a;
`

export const paths = ['/websites/:websiteName/docs']

const DocumentsList = () => {
  const { t } = useTranslation()

  const { websiteName } = useParams()

  const { data: website } = useWebsite(websiteName)
  const websiteId = website?._id

  let {
    data: documents,
    isLoading,
    error,
    refetch: refetchDocuments,
  } = useDocuments({
    websiteId,
    fields: [
      '_id',
      'creationTime',
      'locale',
      'slug',
      'title',
      'websiteId',
      'googleTranslate',
      'originalDocumentId',
    ],
  })

  const locales =
    website?.locales?.filter(locale => locale !== website?.defaultLocale) ?? []

  const defaultLocale = website?.defaultLocale ?? 'en'

  documents = documents.reduce((documents, document) => {
    documents[document.originalDocumentId] =
      documents[document.originalDocumentId] || {}

    documents[document.originalDocumentId][document.locale] = {
      ...document,
    }

    return documents
  }, {})

  documents = Object.values(documents).sort((left, right) =>
    left?.[defaultLocale]?.creationTime > right?.[defaultLocale]?.creationTime
      ? -1
      : 1,
  )

  documents.forEach((document, i) => {
    Object.keys(document).forEach(locale => {
      documents[i][locale].number = documents.length - i
    })
  })

  const [addLanguageDialog, setAddLanguageDialog] = useState(false)

  const googleTranslateDocument = async (documentId, locale) => {
    try {
      let document = await translateDocument(documentId, locale)
      if (document) {
        refetchDocuments()
      }
    } catch (error) {
      console.error(error)
      toast(t(error?.message))
    }
  }

  const showAddLanguageDialog = () => {
    setAddLanguageDialog(true)
  }

  const hideAddLanguageDialog = () => {
    setAddLanguageDialog(false)
  }

  return (
    <div css={{ width: '100%', height: '100%', overflowX: 'auto' }}>
      {isLoading && <div className="loader" />}
      {error && <div>{error}</div>}
      {documents?.length > 0 && (
        <table css={tableContainer}>
          <thead>
            <tr
              css={[
                tableRow,
                {
                  height: 48,
                  lineHeight: '48px',
                  background: 'white',
                  zIndex: 1000,
                  border: 0,
                  position: 'sticky',
                  top: 0,
                  textAlign: 'center',
                  color: 'var(--color-base)',
                  fontWeight: '500',
                },
              ]}
            >
              <th
                css={{
                  background: 'var(--color-background)',
                  border: 0,
                  position: 'sticky',
                  left: 0,
                  maxWidth: 350,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  paddingBottom: '4px',
                  paddingRight: '4px',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                  }}
                >
                  <span
                    css={{
                      background: '#D8E0ED',
                      color: 'var(--color-base)',
                      fontWeight: '600',
                      minWidth: 36,
                    }}
                  >
                    #
                  </span>
                  <div
                    css={[
                      {
                        flexGrow: 1,
                        cursor: 'pointer',
                      },
                      clickable,
                    ]}
                    onClick={event => {
                      event.preventDefault()
                      window.open(
                        `/websites/${websiteName}/docs/by-locale/${defaultLocale}`,
                      )
                    }}
                  >
                    {t(getLanguageNameByCode(defaultLocale))}
                  </div>
                </div>
              </th>
              {locales.map(locale => (
                <th
                  key={locale}
                  css={{
                    minWidth: 150,
                    cursor: 'pointer',
                    paddingBottom: '4px',
                    paddingRight: '4px',
                    background: 'var(--color-background)',
                  }}
                  onClick={event => {
                    event.preventDefault()
                    window.open(
                      `/websites/${websiteName}/docs/by-locale/${locale}`,
                    )
                  }}
                >
                  <div css={[clickable, { background: 'white' }]}>
                    {t(getLanguageNameByCode(locale))}
                  </div>
                </th>
              ))}
              <th
                css={{
                  background: 'var(--color-background)',
                  paddingBottom: 4,
                  paddingRight: 4,
                  minWidth: 192,
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                }}
                onClick={showAddLanguageDialog}
              >
                <div css={[clickable, { background: 'white' }]}>
                  <IconAdd css={{ verticalAlign: 'middle' }} />
                  {t('Add language')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {documents.map((locales2documents, i) => (
              <tr key={locales2documents?.[defaultLocale]?.slug}>
                <td
                  css={[
                    tableColumn,
                    {
                      border: 0,
                      position: 'sticky',
                      left: 0,
                      maxWidth: 480,
                      paddingBottom: '4px',
                      paddingRight: '4px',
                      background: 'var(--color-background)',
                    },
                  ]}
                >
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      background: 'white',
                    }}
                  >
                    <span
                      css={{
                        background: '#D8E0ED',
                        color: 'var(--color-base)',
                        fontWeight: '600',
                        minWidth: 36,
                        textAlign: 'center',
                      }}
                      data-hj-allow
                    >
                      {documents.length - i}{' '}
                    </span>
                    <div
                      css={[
                        {
                          flexGrow: 1,
                          padding: '0px 8px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          cursor: 'pointer',
                          color: '#6A768A',
                        },
                        clickable,
                      ]}
                      onClick={event => {
                        event.preventDefault()
                        window.open(
                          `/websites/${websiteName}/docs/${locales2documents[defaultLocale]._id}/write`,
                        )
                      }}
                      onContextMenu={event => {
                        window.open(
                          `https://www.${website?.customDomain}/docs/en/${locales2documents[defaultLocale].slug}`,
                        )
                      }}
                    >
                      {locales2documents?.[defaultLocale]?.title}
                    </div>
                  </div>
                </td>

                {locales.map(locale => {
                  let result

                  let document = locales2documents[locale]
                  let key = locale

                  if (!document) {
                    result = (
                      <td
                        key={key}
                        css={[
                          tableColumn,
                          {
                            background: 'var(--color-background)',
                            paddingRight: 4,
                            paddingBottom: 4,
                            ':hover': {
                              '> #visibleByDefault': { display: 'none' },
                              '> #visibleOnHover': { display: 'flex' },
                            },
                          },
                        ]}
                      >
                        <div
                          id="visibleByDefault"
                          css={{
                            background: 'white',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <IconClose height="32" width="32" />
                        </div>
                        <div
                          id="visibleOnHover"
                          css={[
                            {
                              background: 'white',
                              height: '100%',
                              display: 'none',
                              cursor: 'pointer',
                              alignItems: 'center',
                              justifyContent: 'center',
                              lineHeight: '48px',
                            },
                            clickable,
                          ]}
                          onClick={event =>
                            googleTranslateDocument(
                              locales2documents[defaultLocale]._id,
                              locale,
                            )
                          }
                        >
                          <IconRobot
                            css={{ paddingRight: 4 }}
                            height="24"
                            width="24"
                          />{' '}
                          {t('Translate')}
                        </div>
                      </td>
                    )
                  } else if (
                    document.translator ||
                    document.googleTranslate === false
                  ) {
                    result = (
                      <td key={key} css={tableColumn}>
                        <div
                          css={[
                            clickable,
                            {
                              background: 'white',
                              textAlign: 'center',
                              cursor: 'pointer',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            },
                          ]}
                          onClick={event => {
                            event.preventDefault()
                            window.open(
                              `/websites/${websiteName}/docs/${document._id}/translate`,
                            )
                          }}
                        >
                          <IconDone height="32" width="32" />
                        </div>
                      </td>
                    )
                  } else if (document.translatorId) {
                    result = (
                      <td
                        key={key}
                        css={[
                          tableColumn,
                          {
                            ':hover': {
                              '> #visibleByDefault': { display: 'none' },
                              '> #visibleOnHover': { display: 'flex' },
                            },
                          },
                        ]}
                      >
                        <div
                          id="visibleByDefault"
                          css={{
                            background: 'white',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <IconPerson height="32" width="32" />
                        </div>
                        <div
                          id="visibleOnHover"
                          css={[
                            {
                              background: 'white',
                              height: '100%',
                              display: 'none',
                              cursor: 'pointer',
                              alignItems: 'center',
                              justifyContent: 'center',
                              lineHeight: '48px',
                            },
                            clickable,
                          ]}
                          onClick={event => {
                            event.preventDefault()
                            window.open(
                              `/websites/${websiteName}/docs/${document._id}/translate`,
                            )
                          }}
                        >
                          <IconPerson
                            css={{ paddingRight: 4 }}
                            height="24"
                            width="24"
                          />{' '}
                          {t('Translating')}
                        </div>
                      </td>
                    )
                  } else if (document.googleTranslate === true) {
                    result = (
                      <td
                        key={key}
                        css={[
                          tableColumn,
                          {
                            ':hover': {
                              '> #visibleByDefault': { display: 'none' },
                              '> #visibleOnHover': { display: 'flex' },
                            },
                          },
                        ]}
                      >
                        <div
                          id="visibleByDefault"
                          css={{
                            background: 'white',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <IconRobot height="32" width="32" />
                        </div>
                        <div
                          id="visibleOnHover"
                          css={[
                            {
                              background: 'white',
                              height: '100%',
                              display: 'none',
                              cursor: 'pointer',
                              alignItems: 'center',
                              justifyContent: 'center',
                              lineHeight: '48px',
                            },
                            clickable,
                          ]}
                          // onClick={() =>
                          //   showTranslationOptionsDialog(
                          //     document,
                          //     locales2documents[defaultLocale],
                          //   )
                          // }
                        >
                          <IconPerson
                            css={{ paddingRight: 4 }}
                            height="24"
                            width="24"
                          />{' '}
                          {t('Translate')}
                        </div>
                      </td>
                    )
                  }

                  return result
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {addLanguageDialog && (
        <AddLanguageDialog
          item={website}
          useUpdateMutation={useUpdateWebsiteMutation}
          close={hideAddLanguageDialog}
        />
      )}
    </div>
  )
}

const DocumentsPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()
  const { websiteName } = useParams()

  return (
    <Layout>
      <AppBar>
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            position: 'fixed',
            maxWidth: 'calc(100vw - 232px)',
          }}
        >
          <AppBarTitle>
            {websiteName} - {t('Documents')}
          </AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <Button
            appearance="secondary"
            href={`https://${websiteName}.globo.support/docs/`}
            css={{ margin: 12, marginRight: 8 }}
          >
            <IconOpenInNewTab
              css={{
                marginRight: 8,
                verticalAlign: 'middle',
              }}
            />
            {t('Open website')}
          </Button>
          <Button
            to={`/websites/${websiteName}/docs/new/write`}
            css={{ margin: 12 }}
          >
            <IconAdd
              css={{
                fill: 'white',
                height: 24,
                width: 24,
                marginRight: 4,
                verticalAlign: 'middle',
              }}
            />
            {t('Add document')}
          </Button>
        </div>
      </AppBar>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <DocumentsList />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default DocumentsPage
