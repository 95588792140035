/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconSend = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-send'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M2.016 21l20.976-9-20.976-9-0.024 7.008 15 1.992-15 1.992z" />
    </svg>
  )
}

export default IconSend
