/* Copyright 2013 - 2024 Waiterio LLC */

import preferCapitalized from './preferCapitalized.js'

export default function getNameFromEmail(email) {
  let [namePart] = email.split('@')
  let [firstName, lastName = ''] = namePart.split(/[._-]/)

  firstName = preferCapitalized(firstName)
  lastName = preferCapitalized(lastName)

  return { firstName, lastName }
}
