/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import deleteInvite from '@globo/client/deleteInvite.js'

export default function useDeleteInviteMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: inviteId => deleteInvite(inviteId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites'] })
    },
    onError: error => error,
  })
}
