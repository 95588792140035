/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import updateMessage from '@globo/client/updateMessage.js'

export default function useUpdateMessageMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: message => updateMessage(message),
    onSuccess: message => {
      queryClient.invalidateQueries({
        queryKey: ['messages', { conversationId: message.conversationId }],
      })
    },
  })
}
