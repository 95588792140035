/* Copyright 2013 - 2024 Waiterio LLC */
import post from './post.js'

export default function translateMessage(body) {
  return post({
    url: 'translate-message',
    body,
  }).catch(error => {
    throw new Error(`Couldn't translate message: ${error}`)
  })
}
