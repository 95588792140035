/* Copyright 2013 - 2024 Waiterio LLC */

const playChimeBellDing = () => {
  let chimeBellDing = document.getElementById('chime_bell_ding')

  if (chimeBellDing) {
    chimeBellDing.volume = 1.0
    chimeBellDing.play()
  } else {
    console.warn('html tag <audio> with id chime_bell_ding not found')
  }
}

export default playChimeBellDing
