/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, {
  Suspense,
  useState,
  useRef,
  useEffect,
  memo,
  useCallback,
} from 'react'
import { jsx, css } from '@emotion/react'
import { useParams, useSearchParams } from 'react-router-dom'
import uuid from '@monorepo/shared/uuid.js'
import useUser from '@globo/react/useUser.js'
import useUsers from '@globo/react/useUsers.js'
import clickable from '@stiloso/styles/clickable.js'
import Button from '@stiloso/components/Button.js'
import useAddMessageSubscription from '@globo/react/useAddMessageSubscription.js'
import useDeleteMessageMutation from '@globo/react/useDeleteMessageMutation.js'
import useConversations from '@globo/react/useConversations.js'
import useConversationMutation from '@globo/react/useUpdateConversationMutation.js'
import useTranslateConversation from '@globo/react/useTranslateConversation.js'
import useTranslateMessage from '@globo/react/useTranslateMessage.js'
import useAddMessageMutation from '@globo/react/useAddMessageMutation.js'
import usePresenceSubscription from '@globo/react/usePresenceSubscription.js'
import useUpdateMessageMutation from '@globo/react/useUpdateMessageMutation.js'
import useWebsite from '@globo/react/useWebsite.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconEmptyMail from '@stiloso/icons/EmptyMailIcon.js'
import IconSupportAgent from '@stiloso/icons/IconSupportAgent.js'
import IconAdd from '@stiloso/icons/IconAdd.js'
import IconWrite from '@stiloso/icons/IconWrite.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import IconMoreVertical from '@stiloso/icons/IconMoreVertical.js'
import IconPerson from '@stiloso/icons/IconPerson.js'
import link from '@stiloso/styles/link.js'
import { toast } from '@monorepo/shared/AndroidToast.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'
import Tabs from '@stiloso/components/Tabs.js'
import Tab from '@stiloso/components/Tab.js'
import Spinner from '@stiloso/components/Spinner.js'
import readFile from '@monorepo/shared/readFile.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import spinner from '@stiloso/styles/spinner.js'
import ImageDialog from '@stiloso/components/ImageDialog.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import useHover from '../hooks/useHover.js'
import getUserId from '../session/getUserId.js'
import useCurrentCustomer from '../queries/useCurrentCustomer.js'
import useCurrentMessages from '../queries/useCurrentMessages.js'
import DocumentsPanel from './ConversationsDocumentsPanel.js'

const suggestionsContainer = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  bottom: 64,
  padding: '16px 0px',
  width: '100%',
  backgroundColor: 'white',
  borderBottom: '2px solid var(--color-background)',
  borderLeft: '2px solid var(--color-background)',
  borderRight: '2px solid var(--color-background)',
  outline: 'none',
})

const SuggestionsSkeleton = () => (
  <div css={suggestionsContainer}>
    <Spinner style={{ margin: 16, padding: 16 }} />
  </div>
)

const MessageComponent = memo(
  ({
    message,
    removeMessage,
    setEditMessage,
    translateConversation,
    customerAvatarUrl,
    customerNickname,
  }) => {
    const {
      _id,
      content,
      from,
      fileUrl,
      hasNote,
      operatorId,
      customerId,
      conversationId,
    } = message

    const { data: operator } = useUser(operatorId, {
      enabled: !!operatorId,
    })

    const customerInitials =
      customerId !== customerNickname
        ? customerNickname?.[0]?.toUpperCase()
        : null

    const { t } = useTranslation()
    const [hoverRef, isHovered] = useHover()
    const [showMoreOptions, setShowMoreOptions] = useState(false)
    const [showImageDialog, setShowImageDialog] = useState(null)

    useEffect(() => {
      function handleClickOutside(event) {
        if (hoverRef.current && !hoverRef.current.contains(event.target)) {
          setShowMoreOptions(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [hoverRef])

    const showEdit =
      (!message.googleTranslate || translateConversation) && !message.fileUrl

    return (
      <div
        onClick={() => showMoreOptions && setShowMoreOptions(false)}
        ref={hoverRef}
        css={[
          {
            display: 'flex',
            alignSelf: from === 'customer' ? 'flex-start' : 'flex-end',
            margin: 4,
          },
          from === 'operator' && { paddingLeft: 16 },
        ]}
      >
        {(isHovered || showMoreOptions) && from === 'operator' && (
          <div
            css={{
              position: 'relative',
              padding: 0,
              marginTop: 'auto',
              marginBottom: 8,
              cursor: 'pointer',
            }}
            onClick={() => setShowMoreOptions(prevState => !prevState)}
          >
            <IconMoreVertical />
            {showMoreOptions && (
              <div
                css={{
                  position: 'absolute',
                  bottom: 0,
                  left: -124,
                  width: 156,
                  zIndex: 9999,
                  backgroundColor: 'white',
                  borderRadius: 4,
                  padding: '8px 0px',
                  border: '1px solid #dcdfe4',
                  fontSize: 14,
                }}
              >
                <div
                  css={[
                    {
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'left',
                      padding: 8,
                      ':hover': {
                        backgroundColor: 'var(--color-red-dark)',
                        color: 'white',
                        fill: 'white',
                      },
                      ':active': {
                        backgroundColor: 'var(--color-red-darker)',
                        color: 'white',
                        fill: 'white',
                      },
                    },
                  ]}
                  onClick={() => removeMessage(_id)}
                >
                  <IconDelete css={{ width: 18, height: 18, marginRight: 8 }} />
                  {t('Remove')}
                </div>
                {showEdit && (
                  <div
                    css={[
                      {
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                        padding: 8,
                      },
                      clickable,
                    ]}
                    onClick={() => setEditMessage(message)}
                  >
                    <IconWrite
                      css={{ width: 18, height: 18, marginRight: 8 }}
                    />
                    {t('Edit')}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {customerAvatarUrl && (
          <div css={{ marginTop: 'auto', marginBottom: 6, marginLeft: 2 }}>
            <ImageGraceful
              css={{
                height: 32,
                width: 32,
                borderRadius: 1000,
                objectFit: 'cover',
              }}
              src={customerAvatarUrl}
            />
          </div>
        )}
        {from === 'customer' && !customerAvatarUrl && (
          <div
            css={{
              height: 32,
              width: 32,
              minWidth: 32,
              borderRadius: 1000,
              color: 'white',
              fontSize: 20,
              background: convertStringToColor(conversationId),
              opacity: 0.75,
              textTransform: 'capitalize',
              marginTop: 'auto',
              marginBottom: 6,
              marginRight: 4,
              padding: 4,
              textAlign: 'center',
            }}
          >
            {customerInitials}
            {!customerInitials && <IconPerson css={{ fill: 'white' }} />}
          </div>
        )}
        {!fileUrl && (
          <div
            key={_id}
            css={[
              {
                background: from === 'customer' ? 'white' : '#EFFFDE',
                color: 'var(--color-base)',
                borderRadius: 8,
                padding: '8px 16px',
                maxWidth: 640,
                margin: 4,
                whiteSpace: 'pre-line',
              },
              hasNote && { background: '#FFFFE0' },
            ]}
          >
            {hasNote && (
              <div
                css={{
                  textDecoration: 'underline',
                  marginBottom: 8,
                  textAlign: 'left',
                }}
              >
                private note:
              </div>
            )}
            {!fileUrl && (
              <span
                css={{
                  a: {
                    color: 'var(--color-primary)',
                    textDecoration: 'underline',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
        )}
        {fileUrl && (
          <ImageGraceful
            src={fileUrl}
            onClick={() => setShowImageDialog(fileUrl)}
            css={{
              borderRadius: 8,
              padding: 16,
              margin: 4,
              minWidth: 280,
              width: '100%',
              maxWidth: 600,
              position: 'relative',
              display: 'block',
              backgroundColor: from === 'customer' ? 'white' : '#EFFFDE',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              cursor: 'zoom-in',
            }}
          />
        )}

        {operator?.avatarUrl && (
          <div css={{ marginTop: 'auto', marginBottom: 6, marginLeft: 2 }}>
            <ImageGraceful
              css={{
                height: 32,
                width: 32,
                borderRadius: 1000,
                objectFit: 'cover',
              }}
              src={operator.avatarUrl.replace('.jpg', '-128w.webp')}
              fallbackSrc={operator.avatarUrl}
            />
          </div>
        )}

        {from === 'operator' && !operator?.avatarUrl && (
          <div
            css={{
              marginTop: 'auto',
              marginBottom: 6,
              marginLeft: 2,
              background: convertStringToColor(operator._id),
              borderRadius: 1000,
              padding: 4,
              width: 32,
              height: 32,
            }}
          >
            <IconSupportAgent css={{ fill: 'white' }} />
          </div>
        )}

        {showImageDialog && (
          <ImageDialog
            src={showImageDialog}
            onClose={() => setShowImageDialog(null)}
          />
        )}
      </div>
    )
  },
)

const Operators = ({
  showOperators,
  setShowOperators,
  mentionUserInNote,
  inputRef,
}) => {
  const { t } = useTranslation()
  const {
    data: operators = [],
    error,
    refetch,
  } = useUsers({}, { enabled: !!showOperators, throwOnError: false })
  const [focusedOperator, setFocusedOperator] = useState(null)
  const operatorsRef = useRef(null)

  useEffect(() => {
    setFocusedOperator(operators?.length && operators[0]._id)
    operatorsRef.current.focus()
  }, [operators])

  useEffect(() => {
    function handleClickOutside(event) {
      if (operatorsRef && !operatorsRef.current.contains(event.target)) {
        setShowOperators(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [setShowOperators])

  const handleKeyDown = event => {
    const currentIndex = operators.findIndex(
      item => item._id === focusedOperator,
    )

    switch (event.key) {
      case 'Backspace': {
        event.preventDefault()
        setShowOperators(false)
        inputRef.current.focus()
        break
      }

      case 'ArrowUp': {
        event.preventDefault()
        const previousIndex =
          (currentIndex - 1 + operators.length) % operators.length
        setFocusedOperator(operators[previousIndex]._id)
        break
      }

      case 'ArrowDown': {
        event.preventDefault()
        const nextIndex = (currentIndex + 1) % operators.length
        setFocusedOperator(operators[nextIndex]._id)
        break
      }

      case 'Enter':
        event.preventDefault()
        mentionUserInNote(operators[currentIndex])
        break
      case 'Escape':
        setShowOperators(false)
        inputRef.current.focus()
        break
      default:
        break
    }
  }

  return (
    <div
      css={suggestionsContainer}
      role="listbox"
      tabIndex="0"
      aria-labelledby="inputLabel"
      onKeyDown={handleKeyDown}
      ref={operatorsRef}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          paddingTop: 0,
          paddingBottom: 16,
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 14,
        }}
      >
        <div css={{ marginLeft: 8 }}>{t('Operators')}</div>
        <div>
          <span css={{ marginRight: 16 }}>
            ↕{' '}
            <span css={{ marginLeft: 2, fontWeight: '200' }}>
              {t('to navigate')}
            </span>
          </span>
          <span css={{ marginRight: 16 }}>
            ⏎{' '}
            <span css={{ marginLeft: 2, fontWeight: '200' }}>
              {t('to select')}
            </span>
          </span>
          <span css={{ marginRight: 16 }}>
            esc{' '}
            <span css={{ marginLeft: 2, fontWeight: '200' }}>
              {t('to exit')}
            </span>
          </span>
        </div>
      </div>
      <div
        css={{
          height: 2,
          background: 'var(--color-background)',
          marginBottom: 16,
        }}
      />
      <div css={{ maxHeight: 280, overflowY: 'auto' }}>
        {!error &&
          operators.map(operator => {
            let displayName = operator.email

            if (operator.firstName && operator.lastName) {
              displayName = `${operator.firstName} ${operator.lastName}`
            }

            return (
              <div
                id={operator._id}
                key={operator._id}
                css={[
                  { padding: 8, display: 'flex' },
                  clickable,
                  focusedOperator === operator._id && {
                    backgroundImage: `linear-gradient(
                              var(--color-hover, rgba(0, 0, 0, 0.1)),
                              var(--color-hover, rgba(0, 0, 0, 0.1))
                            )`,
                  },
                ]}
                onClick={() => mentionUserInNote(operator)}
              >
                {operator.avatarUrl && (
                  <ImageGraceful
                    css={{
                      aspectRatio: '1 / 1',
                      height: 42,
                      borderRadius: 1000,
                    }}
                    retry={{
                      count: 10,
                      delay: 1,
                      accumulate: false,
                    }}
                    src={operator.avatarUrl.replace('.jpg', '-256w.webp')}
                    fallbackSrc={operator.avatarUrl.replace(
                      '.jpg',
                      '-256w.jpg',
                    )}
                  />
                )}
                <span css={{ margin: 'auto 8px' }}>{displayName}</span>
              </div>
            )
          })}
        {!error && !operators.length && (
          <div css={{ padding: 16 }}>{t('No operators found')}</div>
        )}
        {error && (
          <div css={{ padding: 16 }}>
            <span>{error?.message}</span>
            <div css={{ marginTop: 8 }}>
              <Button appearance="success" onClick={refetch}>
                {t('Retry')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const ConversationsMessagesPanel = () => {
  const { t, language } = useTranslation()
  const [searchParams] = useSearchParams()
  const { websiteName } = useParams()
  const { data: website } = useWebsite(websiteName)
  const conversationId = searchParams.get('conversationId')

  const conversationsFilter = {
    websiteId: website?._id,
    sortField: 'lastEditTime',
    sortDirection: 'DESC',
    limit: 100,
  }

  let { data: conversations } = useConversations(conversationsFilter, {
    enabled: !!website._id,
  })

  let currentConversationId = conversationId || conversations[0]?._id

  let currentConversation = conversations.find(
    conversation => conversation._id === currentConversationId,
  )

  let customer = useCurrentCustomer()

  const { data: user } = useUser(getUserId())

  const {
    mutate: mutateConversation,
    error: conversationMutationError,
    reset: resetConversationMutation,
  } = useConversationMutation({
    websiteId: website?._id,
    sortField: 'lastEditTime',
    sortDirection: 'DESC',
  })

  const inputRef = useRef(null)
  const messagesContainerRef = useRef(null)
  const [isUploadingImage, setIsUploadingImage] = useState(false)

  const [messageInput, setMessageInput] = useState('')
  const [editMessage, setEditMessage] = useState(null)
  const [mentionedUserEmails, setMentionedUserEmails] = useState([])
  const [textAreaHeight, setTextareaHeight] = useState('auto')
  const [translateConversation, setTranslateConversation] = useState(false)
  const [showOperators, setShowOperators] = useState(false)
  const [showDocs, setShowDocs] = useState(false)
  const [isMessageANote, setIsMessageANote] = useState(false)

  const messagesFilter = {
    conversationId: currentConversationId,
    sortField: 'creationTime',
    sortDirection: 'DESC',
  }

  usePresenceSubscription({ websiteId: website?._id, operatorId: user?._id })
  useAddMessageSubscription(website?._id)

  let originalMessages = useCurrentMessages()

  let { data: translatedMessages } = useTranslateConversation(
    {
      translateFromLanguage: customer?.language,
      translateToLanguage: language,
      ...messagesFilter,
    },
    { enabled: !!translateConversation },
  )

  originalMessages = originalMessages?.map(message => {
    message ||= {}

    return {
      ...message,
      content: message.content?.replaceAll(
        /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g,
        url => `<a href="${url}" target="_blank">${url}</a>`,
      ),
    }
  })

  let messages = translatedMessages?.length
    ? translatedMessages
    : originalMessages

  let {
    mutate: addMessage,
    error: addMessageError,
    reset: resetAddMessage,
  } = useAddMessageMutation(messagesFilter)
  let {
    mutate: updateMessage,
    error: updateMessageError,
    reset: resetUpdateMessage,
  } = useUpdateMessageMutation()

  let {
    mutate: mutateDeleteMessage,
    error: deleteMessageError,
    reset: resetDeleteMessage,
  } = useDeleteMessageMutation()

  let {
    mutateAsync: translateMessage,
    error: translateMessageError,
    reset: resetTranslateMessage,
    isPending: isTranslatingMessage,
  } = useTranslateMessage(currentConversationId)

  let showTranslation = customer?.language !== website?.defaultLocale

  const resizeTextArea = () => {
    const textarea = inputRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
      setTextareaHeight(`${textarea.scrollHeight}px`)
    }
  }

  const onUpdateMessage = async event => {
    event.preventDefault()

    const oldMessage = editMessage

    const newMessage = {
      ...oldMessage,
      lastEditTime: new Date().toISOString(),
      content: messageInput,
    }

    if (translateConversation) {
      try {
        const { translation } = await translateMessage({
          string: newMessage.content,
          from: newMessage.translateFromLanguage,
          to: newMessage.translateToLanguage,
        })
        newMessage.content = translation
      } catch (error) {
        // console.log(error)
      }
    }

    updateMessage(newMessage)

    setMessageInput('')
    setEditMessage(null)
    setIsMessageANote(false)
    setMentionedUserEmails([])
  }

  const onSendMessage = async event => {
    event.preventDefault()

    const newMessage = {
      _id: uuid(),
      creationTime: new Date().toISOString(),
      conversationId: currentConversation._id,
      organizationId: currentConversation.organizationId,
      websiteId: currentConversation.websiteId,
      content: messageInput,
      customerId: currentConversation.customerId,
      from: 'operator',
      operatorId: getUserId(),
    }

    if (isMessageANote) {
      newMessage.hasNote = true
      newMessage.mentionedUserEmails = mentionedUserEmails
    }

    if (translateConversation) {
      newMessage.googleTranslate = true
      newMessage.translateFromLanguage = user.language
      newMessage.translateToLanguage = customer.language

      try {
        const { translation } = await translateMessage({
          string: newMessage.content,
          from: newMessage.translateFromLanguage,
          to: newMessage.translateToLanguage,
        })
        newMessage.content = translation
      } catch (error) {
        return
      }
    }

    addMessage(
      { message: newMessage },
      {
        onSuccess: () => {
          let newConversation = currentConversation
          newConversation.lastEditTime = new Date().toISOString()
          newConversation.lastReadTime = newConversation.lastEditTime
          newConversation.resolved = false

          if (!isMessageANote) {
            newConversation.lastMessage = messageInput.substring(0, 100)
          }

          mutateConversation(newConversation)
        },
      },
    )
    setMessageInput('')
    setIsMessageANote(false)
    setMentionedUserEmails([])
  }

  const onSendImageMessage = file => {
    setIsUploadingImage(true)
    const newMessage = {
      _id: uuid(),
      creationTime: new Date().toISOString(),
      conversationId: currentConversation._id,
      organizationId: currentConversation.organizationId,
      websiteId: currentConversation.websiteId,
      content: file?.name,
      customerId: currentConversation.customerId,
      type: 'file',
      fileType: file?.extension,
      from: 'operator',
      operatorId: getUserId(),
    }

    addMessage(
      { message: newMessage, file: file?.data },
      {
        onSuccess: () => {
          setIsUploadingImage(false)

          let newConversation = currentConversation
          newConversation.lastEditTime = new Date().toISOString()
          newConversation.lastReadTime = newConversation.lastEditTime
          newConversation.resolved = false
          newConversation.lastMessage = file.name

          mutateConversation(newConversation)
        },
        onError: error => {
          setIsUploadingImage(false)
          console.error(error)
          toast(t(error?.message))
        },
      },
    )

    setMessageInput('')
    setIsMessageANote(false)
  }

  const removeMessage = useCallback(
    id => {
      mutateDeleteMessage(id)
    },
    [mutateDeleteMessage],
  )

  const resolveConversation = () => {
    const resolvedConversation = { ...currentConversation, resolved: true }
    mutateConversation(resolvedConversation)
  }

  const unresolvedConversation = () => {
    const unResolvedConversation = { ...currentConversation, resolved: false }
    mutateConversation(unResolvedConversation)
  }
  const onClickFileUpload = () => {
    document.getElementById('uploadFileInput').click()
  }

  const uploadFile = async event => {
    event.preventDefault()

    let file = event.target.files[0]
    event.target.value = null
    let extension
    if (file.name && file.name.indexOf('.' > -1)) {
      const splits = file.name.split('.')
      extension = splits[splits.length - 1]
    } else if (file.type && file.type.indexOf('/') > -1) {
      const splits = file.type.split('/')
      extension = splits[splits.length - 1]
    }

    if (!['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
      throw new Error('Image should be in .jpg or .png or .webp format')
    }

    const data = await readFile(file)

    file = {
      data,
      extension,
      name: file.name,
    }
    onSendImageMessage(file)
  }

  const mentionUserInNote = teamMember => {
    setMessageInput(
      previousMessage =>
        `${previousMessage}${
          teamMember.firstName.toLowerCase() || teamMember.email
        }`,
    )
    setMentionedUserEmails(previousMentionedUserEmails => [
      ...previousMentionedUserEmails,
      teamMember.email,
    ])
    setShowOperators(false)
    setIsMessageANote(true)
    inputRef.current.focus()
  }

  const addDocumentInMessage = async document => {
    if (document) {
      await setMessageInput(`${document.title} - ${document.url}`)
    }

    setShowDocs(false)

    inputRef.current.focus()
  }

  const handleKeyDown = event => {
    if (event.key === '@') {
      event.preventDefault()
      setMessageInput(previousState => `${previousState}@`)
      setShowOperators(true)
    } else if (event.key === 'Escape' && editMessage) {
      setEditMessage(null)
      setMessageInput('')
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()

      if (messageInput.trim().length !== 0) {
        if (editMessage) {
          onUpdateMessage(event)
        } else {
          onSendMessage(event)
        }
      }
    }
  }

  const onChangeMessage = event => {
    setMessageInput(event.target.value)
  }

  const onPaste = async event => {
    let items = (event.clipboardData || event.originalEvent.clipboardData)
      ?.items

    for (let i = 0; i < items.length; i += 1) {
      if (items[i].kind === 'file') {
        let file = items[i].getAsFile()
        let extension = file.type.split('/')[1]
        if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
          const data = await readFile(file)

          file = {
            data,
            extension,
            name: file.name,
          }

          onSendImageMessage(file)
        }
      }
    }
  }

  useEffect(() => {
    resizeTextArea()
  }, [messageInput])

  useEffect(() => {
    if (messages?.length) {
      const container = messagesContainerRef.current
      const newMessage = messages[messages.length - 1]
      const threshold = -400

      const isAtBottom = container.scrollTop >= threshold
      const isFromOperator = newMessage?.from === 'operator'

      if (isAtBottom || isFromOperator) {
        container.scrollTop = 0
      }
    }
  }, [messages])

  useEffect(() => {
    setMessageInput('')
    setTranslateConversation(false)
    setShowDocs(false)
    setShowOperators(false)
    setIsMessageANote(false)
    setEditMessage(null)
    setMentionedUserEmails([])
  }, [currentConversationId])

  useEffect(() => {
    if (editMessage) {
      setMessageInput(editMessage.content)
      inputRef.current.focus()
    }
  }, [editMessage])

  return (
    <div
      css={{
        height: '100%',
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {messages && (
        <>
          {!currentConversation?.resolved && (
            <div
              css={[
                {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'var(--color-red)',
                  color: 'white',
                  minHeight: 46,
                  position: 'sticky',
                  top: 0,
                },
                clickable,
              ]}
              onClick={resolveConversation}
            >
              {t('Mark this conversation as resolved')}
            </div>
          )}
          {currentConversation?.resolved && (
            <div
              css={[
                {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'var(--color-green)',
                  color: 'white',
                  minHeight: 46,
                  position: 'sticky',
                  top: 0,
                },
                clickable,
              ]}
              onClick={unresolvedConversation}
            >
              {t('This conversation has been resolved')}
            </div>
          )}
          {showTranslation && (
            <div
              css={{
                fontSize: 16,
                backgroundColor: 'white',
                textAlign: 'center',
                padding: 12,
                borderRight: '1px solid #d8e0ed',
                borderLeft: '1px solid #d8e0ed',
                borderBottom: '1px solid #d8e0ed',
                position: 'sticky',
                top: 46,
              }}
            >
              {customer?.nickname && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('{customer_name} speaks <b>{language}</b>')
                      .replace('{customer_name}', customer?.nickname)
                      .replace(
                        '{language}',
                        t(getLanguageNameByCode(customer?.language)),
                      ),
                  }}
                />
              )}

              {!customer?.nickname && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('Customer speaks <b>{language}</b>').replace(
                      '{language}',
                      t(getLanguageNameByCode(customer?.language)),
                    ),
                  }}
                />
              )}

              {customer?.language !== language && !translateConversation && (
                <span
                  css={[link, { marginLeft: 4 }]}
                  onClick={() => setTranslateConversation(true)}
                >
                  {t('Start translating')}
                </span>
              )}
              {translateConversation && (
                <span
                  css={[link, { marginLeft: 4 }]}
                  onClick={() => setTranslateConversation(false)}
                >
                  {t('Stop')}
                </span>
              )}
            </div>
          )}
          <div
            ref={messagesContainerRef}
            css={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column-reverse',
              padding: 16,
              overflow: 'auto',
            }}
          >
            {messages.map(message => (
              <MessageComponent
                key={message._id}
                message={message}
                removeMessage={removeMessage}
                setEditMessage={setEditMessage}
                translateConversation={translateConversation}
                customerAvatarUrl={currentConversation?.customerAvatarUrl}
                customerNickname={currentConversation?.customerNickname}
              />
            ))}
          </div>
          {showOperators && (
            <Suspense fallback={<SuggestionsSkeleton />}>
              <Operators
                showOperators={showOperators}
                setShowOperators={setShowOperators}
                mentionUserInNote={mentionUserInNote}
                inputRef={inputRef}
              />
            </Suspense>
          )}
          {showDocs && (
            <Suspense fallback={<SuggestionsSkeleton />}>
              <DocumentsPanel
                showDocs={showDocs}
                setShowDocs={setShowDocs}
                addDocumentInMessage={addDocumentInMessage}
                inputRef={inputRef}
              />
            </Suspense>
          )}
          <div
            css={{
              backgroundColor: 'white',
              borderLeft: '2px solid var(--color-background)',
              borderRight: '2px solid var(--color-background)',
            }}
          >
            <Tabs styles={{ gridAutoColumns: 'max-content' }}>
              <Tab
                selected={showDocs}
                onClick={() => {
                  setShowDocs(true)
                }}
              >
                {t('Docs')}
              </Tab>
              <Tab
                selected={showOperators}
                onClick={() => {
                  setShowOperators(true)
                }}
              >
                {t('Mention operator')}
              </Tab>
            </Tabs>
          </div>
          <div
            css={{
              display: 'flex',
              backgroundColor: 'white',
              borderLeft: '2px solid var(--color-background)',
              borderRight: '2px solid var(--color-background)',
            }}
          >
            <textarea
              rows={1}
              name="messageInput"
              value={messageInput}
              ref={inputRef}
              onPaste={onPaste}
              onChange={onChangeMessage}
              css={{
                lineHeight: '26px',
                resize: 'none',
                width: '100%',
                overflowY: 'hidden',
                padding: 16,
                height: textAreaHeight,
              }}
              placeholder={t('Send a message...')}
              disabled={isUploadingImage || isTranslatingMessage}
              onKeyDown={handleKeyDown}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              autoFocus
            />

            {(isUploadingImage || isTranslatingMessage) && (
              <div css={{ padding: 16 }}>
                <span css={spinner} />
              </div>
            )}

            {!isUploadingImage && !isTranslatingMessage && (
              <div
                onClick={onClickFileUpload}
                css={[
                  {
                    margin: '0px auto',
                    cursor: 'pointer',
                    padding: 16,
                    maxHeight: 60,
                  },
                  clickable,
                ]}
              >
                <IconAdd
                  css={{
                    width: 28,
                    height: 28,
                  }}
                />
              </div>
            )}

            <input
              id="uploadFileInput"
              type="file"
              accept="image/*"
              capture
              css={{ display: 'none' }}
              tabIndex="-1"
              onChange={uploadFile}
              disabled={isUploadingImage}
            />
          </div>
        </>
      )}

      {conversations.length === 0 && (
        <div
          css={{
            minHeight: '100%',
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            maxWidth: 400,
            flexDirection: 'column',
          }}
        >
          <div css={{ margin: '32px auto' }}>
            <IconEmptyMail
              css={{
                width: 80,
                height: 80,
                fill: '#dcdfe4',
              }}
            />
          </div>
          <p css={{ textAlign: 'center' }}>
            {t('There are no active conversations. To get started, go to')}
          </p>
          <Button to={`/websites/${websiteName}`} fullWidth>
            {t('Settings')}
          </Button>
          <p>{t('and copy-paste the script snippet into your html')}</p>
        </div>
      )}
      {addMessageError && (
        <ErrorDialog error={addMessageError} close={() => resetAddMessage()} />
      )}
      {updateMessageError && (
        <ErrorDialog
          error={updateMessageError}
          close={() => resetUpdateMessage()}
        />
      )}
      {deleteMessageError && (
        <ErrorDialog
          error={deleteMessageError}
          close={() => resetDeleteMessage()}
        />
      )}
      {translateMessageError && (
        <ErrorDialog
          error={translateMessageError}
          close={() => resetTranslateMessage()}
        />
      )}
      {conversationMutationError && (
        <ErrorDialog
          error={conversationMutationError}
          close={() => resetConversationMutation()}
        />
      )}
    </div>
  )
}

const ConversationsMessagesPanelWrapper = () => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <ConversationsMessagesPanel />
    </Suspense>
  </ErrorBoundary>
)

export default ConversationsMessagesPanelWrapper
