/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { useEffect, Suspense, useState } from 'react'
import { jsx } from '@emotion/react'
import { useParams, useNavigate } from 'react-router-dom'
import useWebsite from '@globo/react/useWebsite.js'
import useUpdateWebsiteMutation from '@globo/react/useUpdateWebsiteMutation.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import clickable from '@stiloso/styles/clickable.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import AppBar from '@stiloso/components/AppBar.js'
import Spinner from '@stiloso/components/Spinner.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import IconHelp from '@stiloso/icons/IconHelp.js'
import IconCode from '@stiloso/icons/IconCode.js'
import IconShopify from '@stiloso/icons/IconShopify.js'
import IconWordpress from '@stiloso/icons/IconWordpress.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import HtmlIntegrationDialog from '../dialogs/HtmlIntegrationDialog.js'
import ShopifyIntegrationDialog from '../dialogs/ShopifyIntegrationDialog.js'
import WordpressIntegrationDialog from '../dialogs/WordpressIntegrationDialog.js'

export const paths = ['/websites/:websiteName']

const WebsiteDetails = () => {
  const { t } = useTranslation()
  const { websiteName } = useParams()
  const { data: website } = useWebsite(websiteName)
  const navigate = useNavigate()
  const [htmlIntegrationDialog, setHtmlIntegrationDialog] = useState(false)
  const [shopifyIntegrationDialog, setShopifyIntegrationDialog] =
    useState(false)
  const [wordpressIntegrationDialog, setWordpressIntegrationDialog] =
    useState(false)

  let [name, setName] = useState('')

  const { mutate: mutateWebsite } = useUpdateWebsiteMutation()
  const [isWebsiteEdited, setIsWebsiteEdited] = useState(false)

  const saveWebsite = event => {
    event.preventDefault()

    if (isWebsiteEdited) {
      mutateWebsite(
        {
          ...website,
          name,
          lastEditTime: new Date().getTime(),
        },
        {
          onSuccess: newWebsite => {
            setIsWebsiteEdited(false)
            navigate(`/websites/${newWebsite.name}`)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (website) {
      setName(website.name)
    }
  }, [website])

  return (
    <div>
      {website && (
        <div css={card}>
          <form onSubmit={saveWebsite} disabled>
            <div>
              <label htmlFor="websiteId" css={label}>
                GLOBO_WEBSITE_ID
              </label>
              <input id="websiteId" css={input} value={website._id} readOnly />
            </div>
          </form>
        </div>
      )}
      <div css={[sectionTitle, { marginTop: 16 }]}>
        {t('Chat widget setup instructions')}
      </div>
      <div
        css={[
          card,
          clickable,
          {
            lineHeight: '48px',
            padding: '0px 16px',
            display: 'flex',
            alignItems: 'center',
          },
        ]}
        onClick={() => setHtmlIntegrationDialog(true)}
      >
        <IconCode css={{ height: 32, width: 32 }} />
        <span css={{ marginLeft: 8 }}>HTML</span>
      </div>
      <div
        css={[
          card,
          clickable,
          {
            lineHeight: '48px',
            padding: '0px 16px',
            display: 'flex',
            alignItems: 'center',
            marginTop: 16,
          },
        ]}
        onClick={() => setShopifyIntegrationDialog(true)}
      >
        <IconShopify css={{ height: 32, width: 32 }} />
        <span css={{ marginLeft: 8 }}>Shopify</span>
      </div>
      <div
        css={[
          card,
          clickable,
          {
            lineHeight: '48px',
            padding: '0px 16px',
            display: 'flex',
            alignItems: 'center',
            marginTop: 16,
          },
        ]}
        onClick={() => setWordpressIntegrationDialog(true)}
      >
        <IconWordpress css={{ width: 32, height: 32 }} />
        <span css={{ marginLeft: 8 }}>Wordpress</span>
      </div>
      {htmlIntegrationDialog && (
        <HtmlIntegrationDialog
          websiteId={website?._id}
          close={() => setHtmlIntegrationDialog(false)}
        />
      )}
      {shopifyIntegrationDialog && (
        <ShopifyIntegrationDialog
          close={() => setShopifyIntegrationDialog(false)}
        />
      )}
      {wordpressIntegrationDialog && (
        <WordpressIntegrationDialog
          close={() => setWordpressIntegrationDialog(false)}
        />
      )}
    </div>
  )
}

const WebsitePage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Settings')}</AppBarTitle>
          <div
            css={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              margin: 'auto 0px',
            }}
          >
            <Button href="https://globo.globo.support/docs">
              <IconHelp css={{ fill: 'white' }} />
            </Button>
          </div>
        </AppBar>
        <div css={{ padding: 16 }}>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <WebsiteDetails />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  )
}

export default WebsitePage
