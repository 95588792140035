/* Copyright 2013 - 2024 Waiterio LLC */

export default [
  'alice.it',
  'aol.com',
  'comcast.net',
  'free.fr',
  'gmail.com',
  'gmx.de',
  'googlemail.com',
  'hotmail.co.uk',
  'hotmail.com',
  'hotmail.fr',
  'hotmail.it',
  'icloud.com',
  'libero.it',
  'live.cl',
  'live.com',
  'live.fr',
  'live.it',
  'live.nl',
  'msn.com',
  'outlook.com',
  'orange.fr',
  'sfr.fr',
  'tiscali.it',
  'qq.com',
  'yahoo.com.br',
  'yahoo.co.in',
  'yahoo.co.uk',
  'yahoo.com',
  'yahoo.de',
  'yahoo.fr',
  'yandex.ru',
  'wanadoo.fr',
  'web.de',
]
