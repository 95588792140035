/* Copyright 2013 - 2024 Waiterio LLC */
import { useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import translateConversation from '@globo/client/translateConversation.js'

export default function useTranslateConversation(parameters, options) {
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: ['translate-conversation', { ...parameters }],
    queryFn: () => translateConversation(parameters),
    ...options,
  })

  useEffect(() => {
    if (options?.enabled === false) {
      queryClient.resetQueries({
        queryKey: ['translate-conversation'],
      })
    }
  }, [options?.enabled, parameters?.conversationId, queryClient])

  return {
    ...query,
    data: options?.enabled ? query.data : [],
  }
}
