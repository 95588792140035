/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getCustomer(customerId) {
  let url = `customers/${customerId}`

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get customer ${customerId} : ${error}`)
  })
}
