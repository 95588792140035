/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: label;

  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--color-base);
  line-height: 48px;
`
