/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getCustomers from '@globo/client/getCustomers.js'

export default function useCustomers(parameters, options) {
  return useQuery({
    queryKey: ['customers', { ...parameters }],
    queryFn: () => getCustomers(parameters),
    ...options,
  })
}
