/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useLocation, useNavigate } from 'react-router-dom'
import useWebsites from '@globo/react/useWebsites.js'
import useUpdateWebsiteMutation from '@globo/react/useUpdateWebsiteMutation.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconBrand from '@globo/shared/IconBrand.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import Button from '@stiloso/components/Button.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import useUser from '@globo/react/useUser.js'
import genericEmailDomains from '@stiloso/shared/genericEmailDomains.js'
import getNameFromDomain from '@monorepo/shared/getNameFromDomain.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import getUserId from '../session/getUserId.js'

export const paths = ['/setup-website']

const SetupWebsitePage = () => {
  useLoggedInSession()
  const navigate = useNavigate()
  const { data: websites } = useWebsites()
  const { data: user } = useUser(getUserId())
  const [website] = websites
  const { mutate, isLoading, error, reset } = useUpdateWebsiteMutation()
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)
  let domainPrefilled = urlSearchParams.get('domain')
  const userEmailDomain = user?.email.split('@')[1]
  const isUserEmailGeneric = genericEmailDomains.includes(userEmailDomain)
  const [domain, setDomain] = useState(
    domainPrefilled || (!isUserEmailGeneric && userEmailDomain) || '',
  )
  const [domainError, setDomainError] = useState(null)
  const { t } = useTranslation()

  const changeDomain = event => {
    let domain = event.target.value

    domain = domain.toLowerCase()

    const invalidSpecialCharacters = new RegExp(/[^0-9a-zA-Z.-]/g)

    if (!invalidSpecialCharacters.test(domain)) {
      if (domainError) {
        setDomainError(null)
      }
      if (error) {
        reset()
      }
      setDomain(domain)
    }
  }

  const setupWebsite = async event => {
    event.preventDefault()

    const regexForValidDomain = new RegExp(/^\w+([.-]?\w+)*(\.\w{2,63})+$/)

    if (regexForValidDomain.test(domain)) {
      let name = getNameFromDomain(domain)
      const defaultLocale = getBrowserLanguage()
      const locales = [defaultLocale]

      let creationTime = new Date().toISOString()
      let lastEditTime = creationTime

      let updatedWebsite = {
        ...website,
        name: name.replace(/^-+|-+$/g, ''),
        defaultLocale,
        locales,
        creationTime,
        lastEditTime,
        domain,
      }

      mutate(updatedWebsite, {
        onSuccess: () => {
          navigate('/setup-guide')
        },
      })
    } else {
      setDomainError(t('Please enter a valid website'))
    }
  }

  return (
    <div
      css={{
        height: '100%',
        padding: '48px 0px',
        backgroundColor: 'var(--color-primary)',
      }}
    >
      <div
        css={{
          maxWidth: 1200,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '0px 16px',
          width: '100%',
          backgroundColor: 'var(--color-primary)',
        }}
      >
        <div css={{ padding: '0px 16px' }}>
          <IconBrand light />
        </div>

        <div
          css={{
            margin: '48px 0px',
            textAlign: 'center',
            color: 'white',
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: '24px',
          }}
        >
          {t('Setup website')}
        </div>

        <div
          css={{
            margin: '16px 0px',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <div css={{ width: '100%', maxWidth: 480, padding: '0px 16px' }}>
            <form
              onSubmit={setupWebsite}
              css={{
                background: 'var(--color-background)',
                padding: '16px 32px',
                borderRadius: 8,
              }}
            >
              <div>
                <label htmlFor="domainInput" css={label}>
                  {t('Company website')}
                </label>
                <div
                  css={[
                    input,
                    {
                      display: 'flex',
                      padding: 0,
                      boxShadow: domainError && 'inset 0px 0px 0px 1px red',
                    },
                  ]}
                >
                  <input
                    id="domainInput"
                    css={{
                      display: 'flex',
                      flexGrow: 1,
                      fontWeight: 'bold',
                      color: domainError ? 'red' : '#6A768A',
                      padding: 12,
                      '::placeholder': {
                        fontWeight: 'normal',
                        textTransform: 'lowercase',
                      },
                    }}
                    value={domain || ''}
                    onChange={changeDomain}
                    placeholder={`www.${t('example')}.com`}
                    autoFocus
                    required
                    data-hj-allow
                  />
                </div>
              </div>
              <Button
                type="submit"
                css={{ margin: '32px 0px' }}
                isLoading={isLoading}
                fullWidth
              >
                {t('Save')}
              </Button>
              {error && (
                <div
                  css={{
                    lineHeight: '24px',
                    marginTop: 16,
                    color: 'red',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {t(error?.message)}
                </div>
              )}
              {domainError && (
                <div
                  css={{
                    lineHeight: '24px',
                    marginTop: 16,
                    color: 'red',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {t(domainError)}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupWebsitePage
