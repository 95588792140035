/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useRef } from 'react'
import { jsx } from '@emotion/react'

import IconSearch from '@stiloso/icons/IconSearch.js'
import IconClose from '@stiloso/icons/IconClose.js'

const SearchBar = ({
  inputValue,
  searchBarRef,
  placeholder,
  onSearch,
  onCancel,
  styles,
  inputStyles,
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const inputRef = useRef(null)

  const onChange = async event => {
    let searchText = event.target.value.toLowerCase()
    setSearchQuery(searchText)

    if (onSearch) {
      onSearch(searchText)
    }
  }

  const onCancelSearch = () => {
    setSearchQuery('')
    onCancel()
  }

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      onCancelSearch()
    }
  }

  const handleInputFocus = () => {
    if (inputRef && inputRef.current && inputRef.current.focus) {
      inputRef.current.focus()
    }
  }

  return (
    <div
      ref={searchBarRef}
      onClick={handleInputFocus}
      css={[
        {
          position: 'relative',
          boxShadow: '0px 1.5px 4px rgba(0,0,0,0.24)',
          borderRadius: 10000,
          backgroundColor: 'white',
          display: 'flex',
          flex: 1,
          cursor: 'text',
          height: 48,
        },
        styles,
      ]}
    >
      <span css={{ margin: 'auto 16px' }}>
        <IconSearch css={{ fill: 'var(--color-primary)' }} />
      </span>

      <input
        ref={inputRef}
        value={inputValue || searchQuery}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onCancelSearch}
        onKeyDown={onKeyDown}
        css={[{ width: '100%' }, inputStyles]}
        autoFocus
      />
      <span css={{ margin: 'auto 16px' }}>
        <IconClose
          css={{
            opacity: searchQuery ? 0.5 : 0,
            cursor: searchQuery ? 'pointer' : 'text',
          }}
          onClick={onCancelSearch}
        />
      </span>

      {children}
    </div>
  )
}

export default SearchBar
