/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import ConversationsDesktop from './ConversationsDesktop.js'
import ConversationsSmartphone from './ConversationsSmartphone.js'

export const paths = ['/websites/:websiteName/conversations']

const ConversationsPage = () => (
  <>
    <div css={dLgNone}>
      <ConversationsSmartphone />
    </div>
    <div css={[dNone, dLgBlock]}>
      <ConversationsDesktop />
    </div>
  </>
)

export default ConversationsPage
