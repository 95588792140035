/* Copyright 2013 - 2024 Waiterio LLC */
import readFile from './readFile.js'

export default async function getImageFromEvent(event) {
  const file = event.target.files[0]
  event.target.value = null
  let extension

  if (file.name && file.name.indexOf('.' > -1)) {
    const splits = file.name.split('.')
    extension = splits[splits.length - 1]
  } else if (file.type && file.type.indexOf('/') > -1) {
    const splits = file.type.split('/')
    extension = splits[splits.length - 1]
  }

  if (!['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
    throw new Error('Image should be in .jpg or .png or .webp format')
  }

  return readFile(file)
}
