/* Copyright 2013 - 2024 Waiterio LLC */
import getScreenWidth from './getScreenWidth.js'
import getUserAgent from './getUserAgent.js'

const isiOS = (userAgent, screenWidth) => {
  screenWidth ||= getScreenWidth()
  userAgent ||= getUserAgent()
  let isiOS = /iPhone|iPad|iPod/i.test(userAgent)
  let ismacOS = /Macintosh/i.test(userAgent)
  if (!isiOS && ismacOS && screenWidth < 992) {
    isiOS = true
  }

  return isiOS
}

export default isiOS
