/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import NavBar from '@stiloso/components/NavBar.js'
import NavBarFiller from '@stiloso/components/NavBarFiller.js'
import NavBarSmartphoneMenu from '@stiloso/components/NavBarSmartphoneMenu.js'
import NavBarSmartphoneLink from '@stiloso/components/NavBarSmartphoneLink.js'
import useWebsites from '@globo/react/useWebsites.js'

const LayoutSmartphone = ({ children }) => {
  const { t } = useTranslation()
  let { data: websites } = useWebsites()
  let { websiteName } = useParams()

  websites = websites?.sort((a, b) =>
    a.creationTime > b.creationTime ? 1 : -1,
  )

  // let pathname = ''

  // if (typeof window !== 'undefined') {
  //   pathname = window.location.pathname
  // }

  websiteName = websiteName || websites?.[0]?.name

  const drawerButtons = [
    websites.length > 1 && {
      label: 'Websites',
      to: '/websites',
    },
    {
      label: 'Conversations',
      to: '/websites/{websiteName}/conversations',
    },
    {
      label: 'Customers',
      to: '/websites/{websiteName}/customers',
    },
    {
      label: 'Docs',
      to: '/websites/{websiteName}/docs',
    },
    {
      label: 'Team',
      to: '/team',
    },
    {
      label: 'Settings',
      to: '/websites/{websiteName}',
    },
    {
      label: 'Account',
      to: '/account',
    },
  ].filter(_ => _)

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: '64px auto',
        gridTemplateAreas: `
          'navbar'
          'children'
        `,
      }}
    >
      <div css={{ gridArea: 'navbar' }}>
        <NavBar>
          <a
            aria-label={t('Globo')}
            href="/"
            css={{ color: 'white', padding: 16 }}
          >
            {t('Globo')}
          </a>
          <NavBarFiller />
          <NavBarSmartphoneMenu>
            {drawerButtons.map(({ label, to }) => (
              <NavBarSmartphoneLink
                key={to}
                label={t(label)}
                href={to.replace('{websiteName}', websiteName)}
              />
            ))}
          </NavBarSmartphoneMenu>
        </NavBar>
      </div>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default LayoutSmartphone
