/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import addConversation from '@globo/client/addConversation.js'

export default function useConversationMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: conversation => addConversation(conversation),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['conversations'],
      })
    },
  })
}
