/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Drawer from '@stiloso/components/Drawer.js'
import DrawerButton from '@stiloso/components/DrawerButton.js'
import DrawerTitle from '@stiloso/components/DrawerTitle.js'
import Tabs from '@stiloso/components/Tabs.js'
import Tab from '@stiloso/components/Tab.js'
import IconPowerOff from '@stiloso/icons/IconPowerOff.js'
import IconUser from '@stiloso/icons/IconUser.js'
import { Link, useParams } from 'react-router-dom'
import useWebsites from '@globo/react/useWebsites.js'
import useUser from '@globo/react/useUser.js'
import IconFolders from '@stiloso/icons/IconFolders.js'
import IconSettings from '@stiloso/icons/IconSettings.js'
import IconSend from '@stiloso/icons/IconSend.js'
import IconList from '@stiloso/icons/IconList.js'
// import IconLayers from '@stiloso/icons/IconLayers.js'
import IconEarth from '@stiloso/icons/IconEarth.js'
import IconUsers from '@stiloso/icons/IconUsers.js'
import IconSearchPerson from '@stiloso/icons/IconSearchPerson.js'
import getUserId from '../session/getUserId.js'
import getRoles from '../session/getRoles.js'

const LayoutDesktop = ({ children }) => {
  const { t } = useTranslation()
  let { data: websites } = useWebsites()
  const { data: user } = useUser(getUserId())
  let { websiteName } = useParams()
  let showCampaigns =
    getRoles()[0].organizationId === 'ad217f061ce72100de5e8201'

  websites = websites?.sort((a, b) =>
    a.creationTime > b.creationTime ? 1 : -1,
  )

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  websiteName = websiteName || websites?.[0]?.name

  let fullName = ''

  if (user.firstName && user.lastName) {
    fullName = `${user.firstName} ${user.lastName}`
  }

  const drawerButtons = [
    websites.length > 1 && {
      label: 'Websites',
      to: '/websites',
      Icon: IconFolders,
    },
    {
      label: 'Conversations',
      to: '/websites/{websiteName}/conversations',
      Icon: IconEarth,
    },
    {
      label: 'Customers',
      to: '/websites/{websiteName}/customers',
      Icon: IconSearchPerson,
    },
    showCampaigns && {
      label: 'Campaigns',
      to: '/websites/{websiteName}/campaigns',
      Icon: IconSend,
    },
    {
      label: 'Docs',
      to: '/websites/{websiteName}/docs',
      Icon: IconList,
    },
    {
      label: 'Team',
      to: '/team',
      Icon: IconUsers,
    },
    {
      label: 'Settings',
      to: '/websites/{websiteName}',
      Icon: IconSettings,
    },
  ].filter(_ => _)

  let showNavbar = websites.length > 1

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: showNavbar ? '48px auto' : null,
        gridTemplateColumns: '200px auto',
        gridTemplateAreas: showNavbar
          ? `
          'navbar navbar'
          'drawer children'
        `
          : `
          'drawer children'
        `,
      }}
    >
      {websites.length > 1 && (
        <Tabs
          styles={{
            gridArea: 'navbar',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            background: '#35363A',
            width: '100%',
            position: 'fixed',
            border: 0,
            height: 48,
            top: 0,
          }}
        >
          {websites.map(website => {
            let selected = websiteName === website.name

            return (
              <Link
                key={website.name}
                to={`/websites/${website.name}/conversations`}
                css={{
                  flexGrow: 1,
                  maxWidth: 200,
                }}
              >
                <Tab
                  selected={selected}
                  styles={[
                    {
                      color: '#909192',
                      lineHeight: '48px',
                      textTransform: 'uppercase',
                    },
                    selected && {
                      '&': {
                        boxShadow:
                          '0px -4px 0px var(--color-secondary) inset !important',
                        color: 'var(--color-secondary) !important',
                        fontWeight: 'bold',
                      },
                    },
                  ]}
                >
                  {website.name}
                </Tab>
              </Link>
            )
          })}
        </Tabs>
      )}
      <Drawer top={websites.length > 1 ? 48 : 0}>
        <DrawerTitle>{t('Globo')}</DrawerTitle>
        {drawerButtons.map(({ label, to, Icon }) => (
          <DrawerButton
            key={to}
            label={t(label)}
            to={to.replace('{websiteName}', websiteName)}
            Icon={Icon}
            selected={pathname.endsWith(
              to.replace('{websiteName}', websiteName),
            )}
          />
        ))}
        <div css={{ flexGrow: 1 }} />
        <DrawerButton
          key="/account"
          label={fullName || t('Account')}
          to="/account"
          Icon={IconUser}
          avatarUrl={user.avatarUrl}
        />
        <DrawerButton
          key="/logout"
          label={t('Logout')}
          to="/logout"
          Icon={IconPowerOff}
        />
      </Drawer>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default LayoutDesktop
