/* Copyright 2013 - 2024 Waiterio LLC */
import {
  useMutation,
  useQueryClient,
  useMutationState,
} from '@tanstack/react-query'
import addMessage from '@globo/client/addMessage.js'

export default function useAddMessageMutation(parameters) {
  const queryClient = useQueryClient()
  const translateMessageMutationVariables = useMutationState({
    filters: { status: 'pending', key: 'translate-message' },
    select: mutation => mutation.state.variables,
  })

  return useMutation({
    mutationFn: ({ message, file }) => addMessage({ message, file }),
    onMutate: async ({ message, file }) => {
      await queryClient.cancelQueries({
        queryKey: ['messages', { ...parameters }],
      })

      await queryClient.cancelQueries({
        queryKey: [
          'translate-conversation',
          {
            translateFromLanguage: message.translateToLanguage,
            translateToLanguage: message.translateFromLanguage,
            ...parameters,
          },
        ],
      })

      const previousMessages = queryClient.getQueryData([
        'messages',
        { ...parameters },
      ])

      queryClient.setQueryData(['messages', { ...parameters }], oldMessages => {
        let messages = [{ ...message, file }, ...oldMessages]

        if (oldMessages.length === 0) {
          messages = [{ ...message, file }]
        }
        return messages
      })

      const previousTranslatedMessages = queryClient.getQueryData([
        'translate-conversation',
        {
          translateFromLanguage: message.translateToLanguage,
          translateToLanguage: message.translateFromLanguage,
          ...parameters,
        },
      ])

      if (previousTranslatedMessages) {
        queryClient.setQueryData(
          [
            'translate-conversation',
            {
              translateFromLanguage: message?.translateToLanguage,
              translateToLanguage: message?.translateFromLanguage,
              ...parameters,
            },
          ],
          oldMessages => {
            const { string } = translateMessageMutationVariables[0] || {}

            let messages = [{ ...message, content: string }, ...oldMessages]

            if (oldMessages.length === 0) {
              messages = [{ ...message, content: string }]
            }
            return messages
          },
        )
      }

      return { previousMessages, previousTranslatedMessages }
    },
    onError: (_error, message, context) => {
      queryClient.setQueryData(
        ['messages', { ...parameters }],
        context.previousMessages,
      )

      queryClient.setQueryData(
        [
          'translate-conversation',
          {
            translateFromLanguage: message.translateToLanguage,
            translateToLanguage: message.translateFromLanguage,
            ...parameters,
          },
        ],
        context.previousTranslatedMessages,
      )
    },
  })
}
