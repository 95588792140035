/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: sectiont-title;
  color: var(--color-base);
  font-size: 20px;
  font-weight: bold;
  line-height: 56px;
`
