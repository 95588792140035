/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Modal from '@stiloso/components/Modal.js'
import Button from '@stiloso/components/Button.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import label from '@stiloso/styles/label.js'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'

const avatarContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
  min-width: 48px;
  border-radius: 48px;
`

const CustomerActionsDialog = ({ customer, goToConversation, close }) => {
  const { t } = useTranslation()

  return (
    <Modal close={close} size="small">
      <div
        css={{
          padding: 16,
          maxWidth: 400,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: 'auto',
        }}
      >
        <div
          css={[
            avatarContainer,
            {
              color: 'white',
              fontSize: 28,
              background: convertStringToColor(customer.email),
              textTransform: 'capitalize',
              marginBottom: 8,
            },
          ]}
        >
          {customer.email?.charAt(0)}
        </div>
        <div css={{ marginBottom: 4 }}>{customer.nickname}</div>
        <Button
          onClick={() => goToConversation(customer._id)}
          css={{ marginTop: 16 }}
        >
          {t('Send message')}
        </Button>
      </div>
      <div
        css={{
          height: '1px',
          border: '1px solid var(--color-background)',
          margin: '12px 0',
        }}
      />
      <div css={{ textAlign: 'center' }}>{t('Customer information')}</div>
      <div
        css={{
          height: '1px',
          border: '1px solid var(--color-background)',
          margin: '12px 0',
        }}
      />

      <div css={{ padding: 16 }}>
        <div css={{ margin: '8px 0px' }}>
          <div css={[label, { lineHeight: '28px' }]}>{t('Language')}</div>
          <div css={{ fontSize: 16 }}>
            {getLanguageNameByCode(customer.language)}
          </div>
        </div>
        <div css={{ margin: '8px 0px' }}>
          <div css={[label, { lineHeight: '28px' }]}>{t('Email')}</div>
          <div css={{ fontSize: 16 }}>{customer.email}</div>
        </div>

        {customer.country && (
          <div css={{ margin: '8px 0px' }}>
            <div css={label}>{t('Location')}</div>
            <div css={{ fontSize: 16 }}>{customer.country || '?'}</div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default CustomerActionsDialog
