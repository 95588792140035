/* Copyright 2013 - 2024 Waiterio LLC */
import useMessages from '@globo/react/useMessages.js'
import useCurrentConversationId from './useCurrentConversationId.js'

const useCurrentMessages = () => {
  const currentConversationId = useCurrentConversationId()
  const messagesFilter = {
    conversationId: currentConversationId,
    sortField: 'creationTime',
    sortDirection: 'DESC',
  }
  let { data: currentMessages } = useMessages(messagesFilter, {
    enabled: !!currentConversationId,
  })

  return currentMessages
}

export default useCurrentMessages
