/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconCopyContent = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-copy-content'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 -960 960 960"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
    </svg>
  )
}

export default IconCopyContent
