/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getUsers from '@globo/client/getUsers.js'

export default function useUsers(parameters, options) {
  return useQuery({
    queryKey: ['users', { ...parameters }],
    queryFn: () => getUsers(),
    ...options,
  })
}
