/* Copyright 2013 - 2024 Waiterio LLC */
import localStorage from 'localStorage'
import {
  setAccessTokenForGloboClient,
  setAccessTokenCallbackForGloboClient,
} from '@globo/client/accessToken.js'
import { setRefreshTokenForGloboClient } from '@globo/client/refreshToken.js'
import IS_BROWSER from '@monorepo/env/IS_BROWSER.js'
import clearCookies from '@monorepo/shared/clearCookies.js'

export default function clearSession() {
  if (IS_BROWSER) {
    localStorage.clear()

    setAccessTokenCallbackForGloboClient(null)

    setAccessTokenForGloboClient(null)
    setRefreshTokenForGloboClient(null)

    clearCookies()
  }
}
