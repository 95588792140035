/* Copyright 2013 - 2024 Waiterio LLC */
import put from './put.js'

export default function updatePhrase(conversation) {
  return put({
    url: `conversations/${conversation._id}`,
    body: conversation,
  }).catch(error => {
    throw new Error(`Couldn't update conversation: ${error}`)
  })
}
